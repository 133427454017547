import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Pages
import BankingFinancial from "./Pages/BankingFinancial";
import ConsumerProducts from "./Pages/ConsumerProducts";
import EnergyUtilities from "./Pages/EnergyUtilities";
import Healthcare from "./Pages/Healthcare";
import Home from "./Pages/Home";
import Manufacturing from "./Pages/Manufacturing";
import SmartRetail from "./Pages/SmartRetail";
import Technology from "./Pages/Technology";
import Telecom from "./Pages/Telecom";
import TravelTransportation from "./Pages/TravelTransportation";
import AgricultureLivestock from "./Pages/AgricultureLivestock";
import Environment from "./Pages/Environment";
import Contact from "./Pages/Contact";
import Tracker from "./Pages/Tracker";
import SmartPlaces from "./Pages/SmartPlaces";
import Utilities from "./Pages/Utilities";
import Workspaces from "./Pages/Workspaces";
import Manuals from "./Pages/Manuals";

// Template
import { MainPage } from "./Template/MainPage";

// Translations
import "./translations"

const NotFound = () => {
  return <Redirect to="/" />;
};

const onScroll = (e) => {
  if (e.target.scrollTop > 200) {
    document.querySelector("#topbar1").classList.add("delete");
  }

  if (e.target.scrollTop === 0) {
    document.querySelector("#topbar1").classList.remove("delete");
  }
};

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <MainPage>
            <Home onScroll={onScroll} />
          </MainPage>
        </Route>

        <Route path="/Technology">
          <Technology onScroll={onScroll} />
        </Route>

        <Route path="/SmartRetail">
          <SmartRetail onScroll={onScroll} />
        </Route>

        <Route path="/Tracker">
          <Tracker onScroll={onScroll} />
        </Route>

        <Route path="/Utilities">
          <Utilities onScroll={onScroll} />
        </Route>

        <Route path="/SmartPlaces">
          <SmartPlaces onScroll={onScroll} />
        </Route>

        <Route path="/EnergyUtilities">
          <EnergyUtilities onScroll={onScroll} />
        </Route>

        <Route path="/BankingFinancial">
          <BankingFinancial onScroll={onScroll} />
        </Route>

        <Route path="/Healthcare">
          <Healthcare onScroll={onScroll} />
        </Route>

        <Route path="/Manufacturing">
          <Manufacturing onScroll={onScroll} />
        </Route>

        <Route path="/RetailConsumer">
          <ConsumerProducts onScroll={onScroll} />
        </Route>

        {/* <Route path='/Telecommunications'>
            <Telecom />
          </Route> */}

        <Route path="/TravelTransportation">
          <TravelTransportation onScroll={onScroll} />
        </Route>

        <Route path="/AgricultureLivestock">
          <AgricultureLivestock onScroll={onScroll} />
        </Route>

        <Route path="/Environment">
          <Environment onScroll={onScroll} />
        </Route>

        <Route path="/Workspaces">
          <Workspaces onScroll={onScroll} />
        </Route>

        <Route path="/Contact">
          <Contact onScroll={onScroll} />
        </Route>

        <Route path="/static/manuals">
          <Manuals onScroll={onScroll} />
        </Route>

        <Route path="**" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
