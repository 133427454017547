import React, { useState, useEffect } from "react";
import Topbar from "../Components/Topbar";
import Share from "../Components/Share";
import Footer from "../Components/Footer";

import BenefitsImage from "../Img/Default/art-benefits.svg";
import ConsumerProductsUsecase from "../Img/Default/usecase.svg";
import StyleTemplate from "../css/css-templates/markets-template.module.css";
import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
// import Scrollbar from "smooth-scrollbar";
import { useTranslation } from "react-i18next";

function ConsumerProducts({ onScroll }) {
  const { t } = useTranslation();
  const [numScroll, setNumScroll] = useState(30);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let pageLargura = window.outerWidth < 500 ? 480 : 700;

    var observador = new IntersectionObserver(
      function (entries) {
        entries.forEach(function (entry) {
          //loop em todos os elementos atingidos
          const { isIntersecting, intersectionRatio } = entry;
          if (isIntersecting === true || intersectionRatio > 0) {
            entry.target.classList.add(StyleTemplate.active);
            observador.unobserve(entry.target); // se você só precisar do event uma vez utilize essa linha
          }
        });
      },
      {
        threshold: [0.5],
      }
    );

    //iniciando o opservador para #myDiv
    [...document.querySelectorAll(".animation")].forEach((cada) => {
      observador.observe(cada);
    });
  }, []);

  useEffect(() => {
    if (window.outerWidth > 1400) {
      var options = {
        damping: 0.06,
        alwaysShowTracks: true,
      };
      // Scrollbar.init(document.querySelector(".main"), options);
      // Scrollbar.getAll()[0].track.xAxis.element.remove()
      // Scrollbar.getAll()[0].addListener((e) => {
      //     if (e.offset.y > 70) {
      //         setNumScroll(53)
      //     } else {
      //         setNumScroll(30)
      //     }
      // })

      // return () => { Scrollbar.destroyAll() }
    } else {
      let observadorEl = new IntersectionObserver(
        function (entries) {
          if (entries[0].isIntersecting) {
            setNumScroll(30);
          } else {
            setNumScroll(53);
          }
        },
        {
          threshold: [0.0],
        }
      );

      //iniciando o opservador para #myDiv
      observadorEl.observe(document.querySelector("#targetElement"));
    }
  }, []);

  return (
    <>
      <Topbar
        color={"white"}
        backgroundColor="navy"
        accentColor="var(--base-blue)"
        barsColor="white"
        num={numScroll}
      />
      <div
        onScroll={onScroll}
        className={`main ${StyleTemplate.marketsTemplateContainer} ${StyleTemplate.fixed}`}
      >
        <Helmet>
          <meta name="theme-color" content="#333366" />
          <title>{t('retail.pageTitle')} - Group Link</title>
        </Helmet>

        <div
          style={{
            position: "relative",
            width: "100%",
            height: "60px",
            background: "var(--base-navy)",
          }}
          id="targetElement"
        ></div>

        <div className={StyleTemplate.marketsTemplate}>
          <div className={StyleTemplate.marketsTemplateTitleContainer}>
            <div className={StyleTemplate.marketsTemplatePageTitle}>
              {t('retail.title')}
            </div>
          </div>

          <div
            className={`${StyleTemplate.marketsTemplateIntroContainer} ${StyleTemplate.marketsTemplateRetailConsumerImage}`}
          >
            <div className={StyleTemplate.overlayConsumerProducts}></div>
            <div className={StyleTemplate.marketsTemplateIntroTop}>
              <div className={StyleTemplate.marketsTemplateIntroNavigation}>
                <Link to="/" className={StyleTemplate.marketsTemplateIntroLink}>
                  {t('retail.industries')}
                </Link>
                {` > `}
                <Link
                  to="/RetailConsumer"
                  className={StyleTemplate.marketsTemplateIntroLink}
                >
                  {t('retail.title')}
                </Link>
              </div>

              <div className={StyleTemplate.marketsTemplateIntroTitle}>
                {t('retail.introTitle')}
              </div>

              <div className={StyleTemplate.marketsTemplateIntroDescription}>
                {t('retail.introDesc')}
              </div>

            </div>

            <div className={StyleTemplate.marketsTemplateIntroMiddle}>
              <div className={StyleTemplate.marketsTemplateIntroMiddleTop}>
                <div
                  className={StyleTemplate.marketsTemplateIntroMiddleTopTexts}
                >
                  <div
                    className={`${StyleTemplate.marketsTemplateIntroMiddleTopText} animation`}
                  >
                    {t('retail.introMiddleTopText')}
                  </div>
                  <div
                    className={`${StyleTemplate.marketsTemplateIntroMiddleTopText} animation`}
                  >
                    {t('retail.introMiddleTopTextTwo')}
                  </div>
                </div>

                <div
                  className={`${StyleTemplate.marketsTemplateIntroMiddleTopTitle} animation`}
                >
                  {t('retail.benefits')}
                </div>
              </div>

              <div className={StyleTemplate.marketsTemplateMiddleBottom}>
                <div
                  className={StyleTemplate.marketsTemplateMiddleBottomOptions}
                >
                  <div
                    className={`${StyleTemplate.marketsTemplateMiddleBottomOption} animation`}
                  >
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionTitle
                      }
                    >
                      {t('retail.accurate')}
                    </div>
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionText
                      }
                    >
                      {t('retail.sensorsInstaled')}
                    </div>
                  </div>

                  <div
                    className={`${StyleTemplate.marketsTemplateMiddleBottomOption} animation`}
                  >
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionTitle
                      }
                    >
                      {t('retail.scalable')}
                    </div>
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionText
                      }
                    >
                      {t('retail.activatingUsers')}
                    </div>
                  </div>

                  <div
                    className={`${StyleTemplate.marketsTemplateMiddleBottomOption} animation`}
                  >
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionTitle
                      }
                    >
                      {t('retail.private')}
                    </div>
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionText
                      }
                    >
                      {t('retail.userData')}
                    </div>
                  </div>

                  <div
                    className={`${StyleTemplate.marketsTemplateMiddleBottomOption} animation`}
                  >
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionTitle
                      }
                    >
                      {t('retail.compact')}
                    </div>
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionText
                      }
                    >
                      {t('retail.sensorsLocated')}
                    </div>
                  </div>

                  <div
                    className={`${StyleTemplate.marketsTemplateMiddleBottomOption} animation`}
                  >
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionTitle
                      }
                    >
                      {t('retail.versatile')}
                    </div>
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionText
                      }
                    >
                      {t('retail.theSolution')}
                    </div>
                  </div>

                  <div
                    className={`${StyleTemplate.marketsTemplateMiddleBottomOption} animation`}
                  >
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionTitle
                      }
                    >
                      {t('retail.global')}
                    </div>
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionText
                      }
                    >
                      {t('retail.glSolution')}
                    </div>
                  </div>
                </div>

                <div
                  className={`${StyleTemplate.marketsTemplateMiddleBottomImageContainer} animation`}
                >
                  <img
                    src={BenefitsImage}
                    className={StyleTemplate.marketsTemplateMiddleBottomImage}
                  />
                </div>
              </div>
            </div>

            <div className={StyleTemplate.marketsTemplateOurSolutionsContainer}>
              <div
                className={`${StyleTemplate.marketsTemplateOurSolutionsTop} animation`}
              >
                <div
                  className={StyleTemplate.marketsTemplateOurSolutionsTopTitle}
                >
                  {t('retail.ourSolutions')}
                </div>
                <div
                  className={
                    StyleTemplate.marketsTemplateOurSolutionsTopTextContainer
                  }
                >
                  <div
                    className={StyleTemplate.marketsTemplateOurSolutionsTopText}
                  >
                    {t('retail.plugAndPlay')}
                  </div>
                  <div
                    className={StyleTemplate.marketsTemplateOurSolutionsTopText}
                  >
                    {t('retail.usesTransmission')}
                  </div>
                </div>
              </div>
              <div
                className={`${StyleTemplate.marketsTemplateOurSolutionsBottom} animation`}
              >
                <div
                  className={
                    StyleTemplate.marketsTemplateMiddleBottomUsecaseContainer
                  }
                >
                  <div
                    className={
                      StyleTemplate.marketsTemplateMiddleBottomUsecaseTitle
                    }
                  >
                    {t('retail.useCases')}
                  </div>
                  <div
                    className={
                      StyleTemplate.marketsTemplateMiddleBottomUsecaseText
                    }
                  >
                    {t('retail.userAproach')}
                    {/* <br />
                                <br />
                                Wireless connectivity protocol patented worldwide in 2018, built for IoT (Internet of things) and LBS (Location Based Services) with exclusive differentials in high precision location services (hyperlocation) and consumer identification sensor. more details */}
                  </div>
                </div>
                <div
                  className={
                    StyleTemplate.marketsTemplateMiddleBottomImageContainer
                  }
                >
                  <img
                    src={ConsumerProductsUsecase}
                    className={
                      StyleTemplate.marketsTemplateMiddleBottomImageContainer
                    }
                  />
                </div>
              </div>
            </div>
            <Share />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default ConsumerProducts;
