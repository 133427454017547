import React, { useState, useEffect } from "react";
import Topbar from "../Components/Topbar";
import MessageForm from "../Components/MessageForm";
import Footer from "../Components/Footer";
import Button from "../Components/ButtonPrimary";
import Style from "../css/css-pages/contact.module.css";
import ImageTop from "../Img/Contact/art-contact.svg";
import MobileImage from "../Img/Contact/art-contact-mobile.svg";
import DesktopImage from "../Img/Contact/art-contact.svg";
import MapImageM from "../Img/Contact/map-pin-m.png";
import MapImage from "../Img/Contact/map-pin@2x.png";
import { contactForm } from "../services/TimeService";
import ImageContLeft from "../Img/Contact/art-headquarters.svg";
import { Helmet } from "react-helmet";
import * as EmailValidator from "email-validator";
// import Scrollbar from "smooth-scrollbar"
import {
  GL_EMAIL_SENT_MESSAGE,
  GL_EMAIL_WAS_NOT_SENT_MESSAGE,
} from "../message-constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Headquarters from "../Components/Headquarters";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { phoneRegex } from "../helpers";


const InitialValue = {
  fullname: undefined,
  email: undefined,
  phone: undefined,
  jobtitle: undefined,
  linkedin: undefined,
  industry: undefined,
  company: undefined,
  companyweb: undefined,
  howdidyou: undefined,
  menssage: undefined,
};

function validateEmail(email) {
  if (EmailValidator.validate(email)) {
    return true;
  } else {
    return false;
  }
}

const Contact = ({ onScroll }) => {
  const [values, setValues] = useState(InitialValue);
  const [message, setMessage] = useState({
    status: true,
    msg: GL_EMAIL_SENT_MESSAGE,
    color: "Inative",
    animation: false,
    a: true,
  });
  const [numScroll, setNumScroll] = useState(30);
  const { t } = useTranslation();

  const formGetInTouchSchema = yup.object().shape({
    fullname: yup.string().required(t('contactForm.fullname')),
    email: yup.string().required(t('contactForm.email')).email(),
    phone: yup.string().matches(phoneRegex, t('contactForm.phone')),
    jobtitle: yup.string().required(t('contactForm.jobtitle')),
    linkedin: yup.string().notRequired(),
    industry: yup.string().required(t('contactForm.industry')),
    company: yup.string().required(t('contactForm.company')),
    companyweb: yup.string().notRequired(),
    howdidyou: yup.string().required(t('contactForm.howdidyou')),
    menssage: yup.string().required(t('contactForm.menssage')).min(10)
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formGetInTouchSchema),
  });

  useEffect(() => {
    var options = {
      damping: 0.06,
      alwaysShowTracks: true,
    };
    //    Scrollbar.init(document.querySelector(".main"),options);
    //    Scrollbar.getAll()[0].track.xAxis.element.remove()
    //    Scrollbar.getAll()[0].addListener((e)=>{
    //        if(e.offset.y > 70){
    //            setNumScroll(53)
    //        }else{
    //            setNumScroll(30)
    //        }
    //    })

    //    return ()=>{Scrollbar.destroyAll()}
  }, []);

  useEffect(() => {
    let pageLargura = window.outerWidth < 500 ? 480 : 700;

    var observador = new IntersectionObserver(
      function (entries) {
        entries.forEach(function (entry) {
          //loop em todos os elementos atingidos
          const { isIntersecting, intersectionRatio } = entry;
          if (isIntersecting === true || intersectionRatio > 0) {
            entry.target.classList.add(Style.active);
            observador.unobserve(entry.target); // se você só precisar do event uma vez utilize essa linha
          }
        });
      },
      {
        threshold: [0.5],
      }
    );

    //iniciando o opservador para #myDiv
    [...document.querySelectorAll(".animation")].forEach((cada) => {
      observador.observe(cada);
    });
  }, []);

  const offices = [
    {
      title: "Lisbon Office",
      email: "europe@grouplinknetwork.com",
    },
    {
      title: "Sao Paulo Office",
      email: "latam@grouplinknetwork.com",
    },
  ];

  function onSubmitFunction(data) {
    contactForm(
      data.fullname,
      data.email,
      data.phone,
      data.jobtitle,
      data.linkedin,
      data.industry,
      data.company,
      data.companyweb,
      data.howdidyou,
      data.menssage
    )
      .then((response) => {
        const { message } = response;

        if (message === "ok") {
          setMessage({
            status: true,
            msg: GL_EMAIL_SENT_MESSAGE,
            color: "Green",
            animation: true,
          });
          setTimeout(() => {
            setMessage({
              status: true,
              msg: GL_EMAIL_SENT_MESSAGE,
              color: "Inative",
              animation: true,
            });
          }, 5000);
          setValues(InitialValue);
        } else {
          setMessage({
            status: true,
            msg: GL_EMAIL_WAS_NOT_SENT_MESSAGE,
            color: "Red",
            animation: true,
          });
          setTimeout(() => {
            setMessage({
              status: true,
              msg: GL_EMAIL_SENT_MESSAGE,
              color: "Inative",
              animation: true,
            });
          }, 5000);
        }
      })
      .catch((err) => {
        setMessage({
          status: true,
          msg: GL_EMAIL_WAS_NOT_SENT_MESSAGE,
          color: "Red",
          animation: true,
        });
        setTimeout(() => {
          setMessage({
            status: true,
            msg: GL_EMAIL_SENT_MESSAGE,
            color: "Inative",
            animation: true,
          });
        }, 5000);
      });

    setMessage({
      status: true,
      msg: GL_EMAIL_WAS_NOT_SENT_MESSAGE,
      color: "Red",
      animation: true,
    });
    setTimeout(() => {
      setMessage({
        status: true,
        msg: GL_EMAIL_SENT_MESSAGE,
        color: "Inative",
        animation: true,
      });
    }, 5000);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topbar
        color="white"
        backgroundColor="white"
        barsColor="navy"
        num={numScroll}
      />
      <main onScroll={onScroll} className={`main ${Style.fixed}`}>
        <Helmet>
          <meta name="theme-color" content="#FFFFFE" />
          <title>{t('contact.touch')} - Group Link</title>
        </Helmet>
        <section className={Style.Header}>
          {/* <img src={MobileImage} alt="gl" /> */}
          {/* <img className={Style.Desktop} src={DesktopImage} alt="gl" /> */}
          <div className={Style.HeaderTextCont}>
            <h1>{t('contact.touch')}</h1>
            <div>
              <p>{t('contact.touchDescription')}</p>
              <p>
                {" "}
                {t('contact.descOne')}
                {/* <br /> */}
                {t('contact.descTwo')}
              </p>
            </div>
          </div>
          <div className={Style.HeaderFormCont}>
            <form onSubmit={handleSubmit(onSubmitFunction)}>
              <label>
                {t('contact.fullName')}
                <input
                  name="fullname"
                  type="text"
                  value={values.fullname}
                  className={
                    errors.fullname?.message
                      ? Style.alertBorder
                      : Style.shareFormInput
                  }
                  onChange={(e) =>
                    setValues({ ...values, fullname: e.target.value })
                  }
                  {...register("fullname")}
                />
                <span>{errors.fullname?.message}</span>
              </label>
              <div className={Style.HeaderFormGroup}>
                <label>
                  {t('contact.emailAddress')}
                  <input
                    name="email"
                    id="email"
                    type="text"
                    value={values.email}
                    className={
                      errors.email?.message
                        ? Style.alertBorder
                        : Style.shareFormInput
                    }
                    onChange={(e) =>
                      setValues({ ...values, email: e.target.value })
                    }
                    /*onBlur={onChange}*/ {...register("email")}
                  />
                  <span>{errors.email?.message}</span>
                </label>
                <label>
                  {t('contact.phoneNumber')}
                  <input
                    type="text"
                    name="phone"
                    value={values.phone}
                    className={
                      errors.phone?.message
                        ? Style.alertBorder
                        : Style.shareFormInput
                    }
                    onChange={(e) =>
                      setValues({ ...values, phone: e.target.value })
                    }
                    {...register("phone")}
                  />
                  <span>{errors.phone?.message}</span>
                </label>
              </div>
              <div className={Style.HeaderFormGroup}>
                <label>
                  {t('contact.jobTitle')}
                  <input
                    type="text"
                    name="jobtitle"
                    value={values.jobtitle}
                    className={
                      errors.jobtitle?.message
                        ? Style.alertBorder
                        : Style.shareFormInput
                    }
                    onChange={(e) =>
                      setValues({ ...values, jobtitle: e.target.value })
                    }
                    {...register("jobtitle")}
                  />
                  <span>{errors.jobtitle?.message}</span>
                </label>
                <label>
                  LinkedIn {t('contact.optional')}
                  <div className={Style.HeaderFormLink}>
                    <span>http://</span>
                    <input
                      type="text"
                      name="linkedin"
                      value={values.linkedin}
                      onChange={(e) =>
                        setValues({ ...values, linkedin: e.target.value })
                      }
                      {...register("linkedin")}
                    />
                  </div>
                </label>
              </div>
              <label>
                {t('contact.industry')}
                <input
                  type="text"
                  name="industry"
                  value={values.industry}
                  className={
                    errors.industry?.message
                      ? Style.alertBorder
                      : Style.shareFormInput
                  }
                  onChange={(e) =>
                    setValues({ ...values, industry: e.target.value })
                  }
                  {...register("industry")}
                />
                <span>{errors.industry?.message}</span>
              </label>
              <div className={Style.HeaderFormGroup}>
                <label>
                  {t('contact.company')}
                  <input
                    type="text"
                    name="company"
                    value={values.company}
                    className={
                      errors.company?.message
                        ? Style.alertBorder
                        : Style.shareFormInput
                    }
                    onChange={(e) =>
                      setValues({ ...values, company: e.target.value })
                    }
                    {...register("company")}
                  />
                  <span>{errors.company?.message}</span>
                </label>
                <label>
                  {t('contact.companyWeb')} {t('contact.optional')}
                  <input
                    type="text"
                    name="companyweb"
                    value={values.companyweb}
                    onChange={(e) =>
                      setValues({ ...values, companyweb: e.target.value })
                    }
                  />
                </label>
              </div>
              <label>
                {t('contact.howHear')}
                <input
                  type="text"
                  name="howdidyou"
                  value={values.howdidyou}
                  className={
                    errors.howdidyou?.message
                      ? Style.alertBorder
                      : Style.shareFormInput
                  }
                  onChange={(e) =>
                    setValues({ ...values, howdidyou: e.target.value })
                  }
                  {...register("howdidyou")}
                />
                <span>{errors.howdidyou?.message}</span>
              </label>
              <label>
                {t('contact.message')}
                <textarea
                  type="text"
                  name="menssage"
                  value={values.menssage}
                  className={
                    errors.menssage?.message
                      ? Style.alertBorder
                      : Style.shareFormInput
                  }
                  onChange={(e) =>
                    setValues({ ...values, menssage: e.target.value })
                  }
                  {...register("menssage")}
                ></textarea>
                <span>{errors.menssage?.message}</span>
              </label>
              <div className={Style.HeaderFormFooter}>
                <div className={Style.HeaderFormValidator}></div>
                <div className={Style.HeaderFormButton}>
                  <Button type="submit" title={t('contact.sendMessage')} />
                </div>
              </div>
            </form>
          </div>
        </section>
        <div className={Style.office}>
          <p>{t('contact.ourOffices')}</p>
        </div>

        <Headquarters office={offices} />

        <section data-anime="true" className={Style.Footer}>
          <Footer />
        </section>
      </main>
      {message.status && (
        <MessageForm
          message={message.msg}
          color={message.color}
          animation={message.animation}
        />
      )}
    </>
  );
};

export default Contact;
