import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

// Styles
import "../../css/Index.css";
import Style from "../../css/css-pages/homepage.module.css";

// Images
import GlCardImage from "../../Img/Index/card@2x.png";
import LogoWhite from "../../Img/Index/logo-white.svg";
import BgVideo from "../../Img/Index/bg-home-video.mp4";
import GlPetImage from "../../Img/Index/tracker@2x.png";
import GlStationImage from "../../Img/Index/plug@2x.png";
import GlPlugImage from "../../Img/Index/station@2x.png";
import GlBandImage from "../../Img/Index/utilities@2x.png";
import PlataformImage from "../../Img/Index/dashboard@2x.png";
import PlataformImageMobile from "../../Img/Index/Image@2x.png";
import PlataformImageGraph from "../../Img/Index/plataform-graph.svg";
import PlataformImageUsers from "../../Img/Index/plataform-users@2x.png";
import PlataformImagePeople from "../../Img/Index/plataform-ilustr@2x.png";
import PlataformImageHeatMap from "../../Img/Index/plataform-daytime@2x.png";

// components
import Share from "../../Components/Share";
import Footer from "../../Components/Footer";

// Sections
import { OurSolutions } from "./OurSolutions";
import { HomeHeader } from "./HomeHeader";

const Home = ({ onScroll }) => {
  const [numScroll, setNumScroll] = useState(30);
  const { t } = useTranslation();

  useEffect(() => {
    const text = document.querySelector("h1");

    window.scrollTo(0, 0);
    let pageLargura = window.outerWidth < 500 ? 480 : 700;

    var observador = new IntersectionObserver(
      function (entries) {
        entries.forEach(function (entry) {
          //loop em todos os elementos atingidos
          const { isIntersecting, intersectionRatio } = entry;
          if (isIntersecting === true || intersectionRatio > 0) {
            entry.target.classList.add(Style.active);
            observador.unobserve(entry.target); // se você só precisar do event uma vez utilize essa linha
          }
        });
      },
      {
        threshold: [0.5],
      }
    );

    //iniciando o opservador para #myDiv
    [...document.querySelectorAll(".animation")].forEach((cada) => {
      observador.observe(cada);
    });
  }, []);

  useEffect(() => {
    if (window.outerWidth > 1400) {
      var options = {
        damping: 0.06,
        alwaysShowTracks: false,
      };
      // Scrollbar.init(document.querySelector(".main"), options);
      // Scrollbar.getAll()[0].track._scrollbar.contentEl.style["max-width"] =
      //   "100vw";
      // Scrollbar.getAll()[0].track._scrollbar.contentEl.style["overfow-x"] =
      //   "hidden";
      // Scrollbar.getAll()[0].track.xAxis.element.remove();
      // Scrollbar.getAll()[0].addListener((e) => {
      //   if (e.offset.y > 70) {
      //     setNumScroll(53);
      //   } else {
      //     setNumScroll(30);
      //   }
      // });

      // return () => {
      //   Scrollbar.destroyAll();
      // };
    } else {
      let observadorEl = new IntersectionObserver(
        function (entries) {
          if (entries[0].isIntersecting) {
            setNumScroll(30);
          } else {
            setNumScroll(53);
          }
        },
        {
          threshold: [1.0],
        }
      );
      //iniciando o opservador para #myDiv
      observadorEl.observe(document.querySelector("#targetElement"));
    }
  }, []);

  return (
    <>
      <div
        style={{ maxHeight: "100vh", maxWidth: "100vw", overflowY: "scroll" }}
        className={`${Style.homepageMain} main`}
        onScroll={onScroll}
        id="main_home"
      >
        <Helmet>
          <meta name="theme-color" content="#FFFFFE" />
          <title>Group Link - Software-Based IoT Network</title>
        </Helmet>

        <div style={{ width: "60px", height: "60px" }} id="targetElement"></div>

        <HomeHeader />

        <section className={Style.homepageHeaderBringItToLife}>
          <div style={{ position: "relative", padding: "0 0px 0px 0px" }}>
            <div className={`${Style.homepageHeaderBringSubtitle} animation`}>
              <h2>{t('home.bringItToLife.title')}</h2>
            </div>

            <div
              className={`${Style.homepageHeaderBringItToLifeGrid} animation`}
            >
              <div>
                <p>
                  <span className="welcomeText">
                    {t('home.bringItToLife.textOne')}
                  </span>
                  <span style={{ whiteSpace: "nowrap" }}>
                    {t('home.bringItToLife.gl')}
                  </span>
                  <span>{t('home.bringItToLife.textOneCont')}</span>
                </p>
              </div>

              <div>
                <p>{t('home.bringItToLife.textTwo')}</p>
              </div>

              <div className={Style.imageLogoContainer}>
                <img
                  className={`${Style.grouplinklogo} animation`}
                  src={LogoWhite}
                  alt="gl-logo"
                />
              </div>
            </div>
          </div>

          <div className={Style.homepageHeaderGroundbreakingGroup}>
            <div>
              <div
                className={`${Style.homepageHeaderGroundbreakingIcons} gl-lowcost`}
              />
              <span>{t('home.groundbreaking.hightlyAccurate')}</span>
            </div>

            <div>
              <div
                className={`${Style.homepageHeaderGroundbreakingIcons} gl-scale`}
              />
              <span>{t('home.groundbreaking.totallyScalable')}</span>
            </div>

            <div>
              <div
                className={`${Style.homepageHeaderGroundbreakingIcons} gl-deploy`}
              />
              <span>{t('home.groundbreaking.untouchedPrivacy')}</span>
            </div>
          </div>
        </section>

        <section
          style={{ position: "relative", overflow: "hidden" }}
          className={Style.homepageHeaderGroundbreaking}
        >
          <video
            style={{
              position: "absolute",
              objectFit: "cover",
              left: "0",
              top: 0,
              width: "100vw",
            }}
            width="100%"
            height="100%"
            autoPlay
            loop
            muted
          >
            <source src={BgVideo} type="video/mp4" />
          </video>

          <div
            className={`${Style.homepageHeaderGroundbreakingTitle} animation`}
          >
            <p>{t('home.groundbreaking.title')}</p>
          </div>
          <div
            className={`${Style.homepageHeaderGroundbreakingGroup} animation`}
          >
            <div>
              <div
                className={`${Style.homepageHeaderGroundbreakingIcons} gl-lowcost`}
              />
              <span>{t('home.groundbreaking.hightlyAccurate')}</span>
            </div>

            <div>
              <div
                className={`${Style.homepageHeaderGroundbreakingIcons} gl-scale`}
              />
              <span>{t('home.groundbreaking.totallyScalable')}</span>
            </div>

            <div>
              <div
                className={`${Style.homepageHeaderGroundbreakingIcons} gl-deploy`}
              />
              <span>{t('home.groundbreaking.untouchedPrivacy')}</span>
            </div>
          </div>

        </section>

        <OurSolutions />

        <section className={Style.OurDevices}>
          <div className={`${Style.title} ${Style.deviceLineTitle} animation`}>
            <h1>{t('home.OurDevices.title')}</h1>
            <span>{t('home.OurDevices.subtitle')}</span>
          </div>
          {/* <h1 className={Style.deviceLineTitle}>{t('home.OurDevices.title')}</h1> */}

          <div className={Style.OurDeviceLineup}>
            <div className={Style.Left}>
              <img className="animation" src={GlPlugImage} alt="gl-device" />
              <span>
                <strong>GL</strong>Station
              </span>
            </div>

            <div className={Style.Right}>
              <div className={`${Style.RightCont} ${Style.um}`}>
                <img className="animation" src={GlBandImage} alt="gl-device" />
                <span>
                  <strong>GL</strong>Utilities
                </span>
              </div>
              <div className={`${Style.RightCont} ${Style.dois}`}>
                <img
                  className="animation"
                  src={GlStationImage}
                  alt="gl-device"
                />
                <span>
                  <strong>GL</strong>Plug
                </span>
              </div>
              <div className={`${Style.RightCont} ${Style.tres}`}>
                <img className="animation" src={GlPetImage} alt="gl-device" />
                <span>
                  <strong>GL</strong>Pet
                </span>
              </div>
              <div className={`${Style.RightCont} ${Style.quatro}`}>
                <img className="animation" src={GlCardImage} alt="gl-device" />
                <span>
                  <strong>GL</strong>Card
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className={Style.plataform}>
          <div className={`${Style.title} animation`}>
            <h1>{t('home.intuitivePlatform.title')}</h1>
            <span>{t('home.intuitivePlatform.subtitle')}</span>
          </div>
          <div className={`${Style.plataformImage} animation`}>
            <img
              className={Style.imageMobile}
              src={PlataformImageMobile}
              alt="gl"
            />
            <img
              className={Style.imageDesktop}
              src={PlataformImage}
              alt="ImageGl"
            />
            <img
              className={Style.imageDesktopHeatmap}
              src={PlataformImageHeatMap}
              alt="ImageGl"
            />
            <img
              className={Style.imageDesktopPeople}
              src={PlataformImagePeople}
              alt="ImageGl"
            />
            <img
              className={Style.imageDesktopUsers}
              src={PlataformImageUsers}
              alt="ImageGl"
            />
            <img
              className={Style.imageDesktopGraph}
              src={PlataformImageGraph}
              alt="ImageGl"
            />
          </div>
        </section>

        <section>
          <Share />
        </section>

        <section>
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Home;
