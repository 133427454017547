import FontColor from './css/topbarFontColor.module.css'

function topbarFontColor(color) {
    let topbarColor = FontColor[color]
    switch (color) {
        case 'white':
            topbarColor = FontColor.whiteColor
            break
        case 'black':
            topbarColor = FontColor.black
            break
        default:
            topbarColor = FontColor.black
            break
    }
    return topbarColor
}

export default topbarFontColor
