import { Box } from "@mui/material";
import Topbar from "../Components/Topbar";

export const MainPage = ({ children }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        overflowX: "hidden",
      }}
    >
      <Topbar color="black" backgroundColor="white" barsColor="navy" num={30} />
      {children}
    </Box>
  );
};
