import React from 'react'
import Styles from '../css/css-components/message-form.module.css'

export default function MessageForm({message,color,animation}) {
    return (
        <div className={`${Styles.MessageForm} ${Styles[color]} ${animation && Styles.animationShow}`}>
            {message}
        </div>
    )
}
