
export  async function time() {
    const response = await fetch(`/api/time`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    })
    console.log(response)
    return response.json();
}

export  async function serviceEmail(name, email) {

    
    const response = await fetch(`/api/web/users_email`, {
        method: 'POST',
        headers: { 'Content-Type':'application/json'  },
        body: JSON.stringify({ name: name , email: email })
    })
    console.log(response)
    return await response.json()
}


export  async function contactForm(fullname,email,phone,jobtitle,linkedin,industry,company,companyweb,howdidyou,menssage) {

   

    const response = await fetch(`/api/web/formpost`, {
        method: 'POST',
        headers: { 'Content-Type':'application/json'  },
        body: JSON.stringify({ fullname: fullname, email: email, phone: phone, jobtitle: jobtitle, linkedin: linkedin, industry: industry, company: company, 
            companyweb: companyweb, howdidyou: howdidyou, menssage: menssage })
    })
    
    return await response.json();
}

export  async function sharePost(first_name, last_name, email, phone, description) {

    const response = await fetch(`/api/web/sharepost`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ first_name: first_name, last_name: last_name, email: email, phone: phone, description: description })
    })
    return await response.json()
}