import React from 'react'
import Styles from '../css/css-components/buttonPrimary.module.css'

function ButtonPrimary({ title, ...rest }) {

    return (
        <button className={Styles.buttonPrimary} {...rest}>
            {title}
        </button>
    )
}

export default ButtonPrimary
