import { useState, useLayoutEffect, useEffect } from "react";
import gsap from "gsap";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import animationData from "../animation.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { HashLink as Link } from "react-router-hash-link";

//Styles
import Style from "../../css/css-pages/home/header.module.css";

// Components
import WriteText from "../../Components/WriteText";
import ButtonPrimary from "../../Components/ButtonPrimary";
import { WriteTextTwo } from "../../Components/WriteTextTwo";

export function HomeHeader() {
  const { t, i18n } = useTranslation();

  const rendererSettings = {
    preserveAspectRatio: "xMidYMid slice",
    viewBoxSize: "-350 0 1920 1400",
  };

  const [tl, setTL] = useState();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTL(gsap.timeline({}));
    });
    return () => ctx.revert();
  }, [i18n.language]);

  useEffect(() => {
    const homeSubititle = document.getElementById("home_subtitle");
    homeSubititle.innerHTML = t('home.banner.subtitle')
  }, [t])

  return (
    <section className={Style.homepageHeader}>
      <div className={Style.homepageHeaderContTitle}>
        <div className={Style.container}>
          {/* style={{ height: 220 }} */}
          {/* <WriteText
            className={Style.welcomeText}
            text={t('home.banner.titleOne')}
            mainTL={tl}
          />
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
              }}
            >
              <WriteText
                className={Style.evolutionText}
                text={t('home.banner.titleIoio')}
                mainTL={tl}
                opts={{ yoyo: true, repeat: 1 }}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
              }}
            >
              <WriteText
                className={Style.revolutionText}
                preservePointer={true}
                text={t('home.banner.titleTwo')}
                mainTL={tl}
              />
            </Box>
          </Box> */}
          { i18n.language.includes('en') && (
            <WriteTextTwo
              titleOne="Welcome to the IoT"
              titleIoio="evo"
              titleTwo="revolution"
              className={Style.welcomeText}
            />
          )}
          { i18n.language.includes('pt') && (
            <WriteTextTwo
              titleOne="Bem-vindo à"
              titleIoio="evo"
              titleTwo="revolução IoT"
              className={Style.welcomeText}
            />
          )}
        </div>

        <p className={`animation ${Style.homeSubititle}`} id="home_subtitle" >
          {/* {t('home.banner.subtitle')} */}
        </p>

        <div className={Style.homepageHeaderButton}>
          <Link to="Contact">
            <ButtonPrimary title={t('home.banner.talkTeam')} />
          </Link>
        </div>
      </div>

      <div className="animation2">
        <Player
          rendererSettings={rendererSettings}
          keepLastFrame
          autoplay
          onStateChange={(e) => {
            console.log(e);
          }}
          src={animationData}
          style={{ height: "100%", width: "100%" }}
        ></Player>
      </div>
    </section>
  )
}
