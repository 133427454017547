import { useEffect, useState } from "react";
import Styles from "../css/css-components/language-switcher.module.css";
import topbarFontColor from "../func/topbarFontColor";
import { useTranslation } from "react-i18next";
import { BsGlobe } from "react-icons/bs";

function LanguageSwitcher({ color, isOnMobile = false }) {
  const [internationalizationIsShowing, setInternationalizationIsShowing] = useState(null);
  const { i18n } = useTranslation()

  function handleChangeLanguage(event) {
    const language = event.target.dataset.value; // pt || en
    const availableLanguages = ["pt", "en"];

    if (availableLanguages.includes(language)) {
      i18n.changeLanguage(language);
      setInternationalizationIsShowing(false);
    }
  }

  function handleShowInternationalization() {
    if (isFirstRender) {
      setInternationalizationIsShowing(true);
    } else {
      setInternationalizationIsShowing(!internationalizationIsShowing);
    }
  }

  const isFirstRender = internationalizationIsShowing === null;

  useEffect(() => {

    function closeInternationalization(event) {
      const isClickInside = event.target.closest(`.${Styles.topbarInternationalizationContainer}`) !== null;
      const isClickButton = event.target.closest(`.${Styles.topbarInternationalizationButton}`) !== null;

      if (!isClickInside && !isClickButton && !isFirstRender) {
        setInternationalizationIsShowing(false);
      }
    }

    document.addEventListener("click", closeInternationalization);

    return () => {
      document.removeEventListener("click", closeInternationalization);
    }
  }, [isFirstRender])

  return (
    <div
      className={`
      ${Styles.topbarButton}
      ${isOnMobile && Styles.topbarInternationalizationMobile}
      `}
    >
      <button
        className={`${Styles.topbarInternationalizationButton} ${topbarFontColor(color)}`}
        onClick={handleShowInternationalization}
      >
        <BsGlobe
          className={`${topbarFontColor(color)}`}
          size={32}
        />
      </button>

      <div
        className={`
          ${Styles.topbarInternationalizationContainer}
          ${ isFirstRender ? Styles.topbarInternationalizationContainer : (
            internationalizationIsShowing
            ? Styles.topbarInternationalizationContainerShow
            : Styles.topbarInternationalizationContainerHide
        )}
        `}
      >
        <button
          data-value="pt"
          onClick={handleChangeLanguage}
          className={i18n.language.includes('pt') && Styles.topbarInternationalizationButtonSelected}
        >
          Português
        </button>

        <button
          data-value="en"
          onClick={handleChangeLanguage}
          className={i18n.language.includes('en') && Styles.topbarInternationalizationButtonSelected}
        >
          English
        </button>
      </div>
    </div>
  )
}

export default LanguageSwitcher;
