import { useEffect, useState } from "react"

export const useMedia = (media) => {
    const [mediaIsActive, setMediaIsActive] = useState(false)

    useEffect(() => {

        const verifyMediaQuerie = () => {
            const { matches } = window.matchMedia(`(max-width: ${media}px)`)
            setMediaIsActive(matches)
        }
        verifyMediaQuerie();

        window.addEventListener('resize', verifyMediaQuerie)

        return () => {
            window.removeEventListener('resize', verifyMediaQuerie)
        }
    }, [media])

    return { mediaIsActive }
}
