import React, { Component } from 'react'

class Benefits extends Component {
    render() {
        return(
            <div className='benefits-info-container container-fluid'>
                        <div className='benefits-i-items row'>
                            <div className='col-12 col-xl-4 benefits-i-item i-item-mobile'>
                                <div className='benefits-item-icon gl-compass' />
                                <h4 className='benefits-item-title'>Real-Time Location Systems</h4>
                                <div className='benefits-item-text'>Wireless connectivity protocol patented worldwide in 2018, built for IoT (Internet of things) and LBS (Location Based Services) with exclusive differentials in high precision location services (hyperlocation) and consumer identification sensor. Group Link™ technology allows integration of both worlds On-Line and Off-Line (Physical World).</div>
                            </div>

                            <div className='col-12 col-xl-4 benefits-i-item i-item-mobile'>
                                <div className='benefits-item-icon gl-pin' />
                                <h4 className='benefits-item-title'>Indoor Positioning Systems</h4>
                                <div className='benefits-item-text'>GL Sensors communicate with smartphones in close proximity without the need for pairing, Wifi or Bluetooth. Connection is achieved using a Group Link™ code (GL SDK) installed in customer and partner applications and also a sensor that is installed at any point of interest at the POS.</div>
                            </div>

                            <div className='col-12 col-xl-4 benefits-i-item'>
                                <div className='benefits-item-icon gl-minimize' />
                                <h4 className='benefits-item-title'>Proximity Detection</h4>
                                <div className='benefits-item-text'>GL Sensors communicate with smartphones in close proximity without the need for pairing, Wifi or Bluetooth. Connection is achieved using a Group Link™ code (GL SDK) installed in customer and partner applications and also a sensor that is installed at any point of interest at the POS.</div>
                            </div>
                        </div>
                    </div>
        )
    }
}

export default Benefits