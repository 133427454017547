import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMedia } from '../../hooks/useMedia'
import Slider from "react-slick";

// styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/css-pages/home/our-solutions.css"

import Styles from '../../css/css-pages/home/our-solutions.module.css'
import StyleHome from "../../css/css-pages/homepage.module.css";

// images
import SmartPlacesUsecase from "../../Img/SmartPlaces/usecase-smartplaces.svg";
import AgricultureLivestockUsecase from "../../Img/Utilities/usecase-utilities.svg";
import DefaultUsecase from "../../Img/Default/usecase.svg";
import HealthcareUsecase from "../../Img/Healthcare/usecase-healthcare.svg";
import ManufacturingUsecase from "../../Img/Tracker/usecase-tracker.svg";
import UtilitiesUsecase from "../../Img/Utilities/usecase-utilities.svg";

import SmartRetailLogo from "../../Img/SmartRetail/SmartRetailLogo.svg";
import SmartPlacesLogo from "../../Img/SmartPlaces/smartplaces-logo.svg";
import UtilitiesLogo from "../../Img/Utilities/utilities-logo.svg";
import TrackerLogo from "../../Img/Tracker/tracker-logo.svg";
import ArtHomeUseCase from "../../Img/Default/art-home-usecases.svg"

const LIST_ITEM_HEIGHT_DESK = 50
const LIST_ITEM_WIDTH_MB = 200

export function OurSolutions() {
  const [itemIdActive, setItemIdActive] = useState(0)
  const [indicatorPosition, setIndicatorPosition] = useState(0)
  const [changeItemActiveIsDisabled, setChangeItemActiveIsDisabled] = useState(false) // [true, false
  const { mediaIsActive: isMobileMedia } = useMedia(1199)
  const { mediaIsActive: isMobileMedia750 } = useMedia(750)
  const sliderRef = useRef(null)

  const { t } = useTranslation()

  const itensList = [
    {
      title: t('home.ourIndustries.banking'),
      text: t('home.ourIndustries.bankingText'),
      link: '#',
      src: DefaultUsecase,
      logo: SmartPlacesLogo
    },
    {
      title: t('home.ourIndustries.retail'),
      text: t('home.ourIndustries.retailText'),
      link: '#',
      src: SmartPlacesUsecase,
      logo: SmartRetailLogo
    },
    {
      title: t('home.ourIndustries.logistics'),
      text: t('home.ourIndustries.logisticsText'),
      link: '#',
      src: DefaultUsecase,
      logo: SmartRetailLogo
    },
    {
      title: t('home.ourIndustries.security'),
      text: t('home.ourIndustries.securityText'),
      link: '#',
      src: UtilitiesUsecase,
      logo: UtilitiesLogo
    },
    {
      title: t('home.ourIndustries.waterManagement'),
      text: t('home.ourIndustries.waterManagementText'),
      link: '#',
      src: UtilitiesUsecase,
      logo: UtilitiesLogo
    },
    {
      title: t('home.ourIndustries.industry'),
      text: t('home.ourIndustries.industryText'),
      link: '#',
      src: UtilitiesUsecase,
      logo: UtilitiesLogo
    },
    {
      title: t('home.ourIndustries.smartCities'),
      text: t('home.ourIndustries.smartCitiesText'),
      link: '#',
      src: UtilitiesUsecase,
      logo: UtilitiesLogo
    },
  ].map((item, index) => ({ ...item, id: index }))

  const itensListSortedByTitle = itensList
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((item, index) => ({ ...item, id: index }))

  // console.log(itensListSortedByTitle)

  const itemActive = itensListSortedByTitle.find(item => item.id === itemIdActive)
  const list = document.querySelector(`.${Styles.list}`)

  const handleKeyUp = (event, id) => {
    if (event.key === 'Enter') {
      handleChangeItemActive(id)
    }
  }

  const handleChangeItemActive = (id) => {

    if (id === itemIdActive) return

    if (changeItemActiveIsDisabled) return

    setChangeItemActiveIsDisabled(true)

    setIndicatorPosition(id)
    const idIsBiggerThanItemIdActive = id > itemIdActive
    const direction = getAnimationDirection(idIsBiggerThanItemIdActive)

    classToAnimateContent(direction)

    setTimeout(() => {
      setItemIdActive(id)
    }, 300) // 300ms is the time of the animation

  }

  const classToAnimateContent = (direction) => {

    const content = document.querySelector(`.${Styles.content}`)
    // 'up', 'down', 'left', 'right'

    switch (direction) {
      case 'up':
        content.classList.add(Styles.animateUp)
        content.classList.remove(Styles.animateDown)
        break;
      case 'down':
        content.classList.add(Styles.animateDown)
        content.classList.remove(Styles.animateUp)
        break;
      case 'left':
        content.classList.add(Styles.animateLeft)
        content.classList.remove(Styles.animateRight)
        break;
      case 'right':
        content.classList.add(Styles.animateRight)
        content.classList.remove(Styles.animateLeft)
        break;
      default:
        break;
    }

    setTimeout(() => {
      content.classList.remove(Styles.animateDown)
      content.classList.remove(Styles.animateUp)
      content.classList.remove(Styles.animateLeft)
      content.classList.remove(Styles.animateRight)
      setChangeItemActiveIsDisabled(false)
    }, 1000)

  }

  const getAnimationDirection = (idIsBiggerThanItemIdActive) => {
    switch (idIsBiggerThanItemIdActive) {
      case true:
        return isMobileMedia ? 'right' : 'down'
      case false:
        return isMobileMedia ? 'left' : 'up'
      default:
        return 'up'
    }
  }

  const handleOnSwipe = (direction) => {
    if (changeItemActiveIsDisabled) return

    // console.log(props)
    // with infinite mode
    // if (direction === 'left') {
    //   const nextId = itemIdActive + 1 >= itensList.length ? 0 : itemIdActive + 1
    //   handleChangeItemActive(nextId)
    // } else {
    //   const nextId = itemIdActive - 1 < 0 ? itensList.length - 1 : itemIdActive - 1
    //   handleChangeItemActive(nextId)
    // }


    // without infinite
    if (direction === 'left') {
      const nextId = itemIdActive + 1 >= itensList.length ? itemIdActive : itemIdActive + 1
      handleChangeItemActive(nextId)
    } else {
      const nextId = itemIdActive - 1 < 0 ? itemIdActive : itemIdActive - 1
      console.log(nextId)
      handleChangeItemActive(nextId)
    }
  }

  const handleClickOnCardMobile = (id) => {
    if (isMobileMedia && !changeItemActiveIsDisabled) {
      sliderRef.current?.slickGoTo(id)
      handleChangeItemActive(id)
    }
  }

  const settings = {
    className: Styles.list,
    infinite: false,
    speed: 500,
    slidesToShow: isMobileMedia750 ? 2 : 3.5,
    slidesToScroll: 1,
    variableWidth: true

  };

  return (
    <section className={Styles.container}>
      <div className={`${StyleHome.title} ${Styles.titleSection} animation`}>
        <h1>{t('home.ourIndustries.title')}</h1>
        <span>{t('home.ourIndustries.subtitle')}</span>
      </div>

      <div className={Styles.body}>
        {isMobileMedia ? (
          <Slider
            onSwipe={(e) => handleOnSwipe(e)}
            ref={sliderRef}
            swipe={!changeItemActiveIsDisabled}
            {...settings}
          >
            {itensListSortedByTitle.map(item => (
              <div
                className={`listItem ${Styles.listItem} ${item.id === itemIdActive ? Styles.active : ''}`}
                tabIndex={0}
                key={item.id}
                role='button'
                id={`list_item_${item.id}`}
                onClick={() => handleClickOnCardMobile(item.id)}
              >
                <span>{item.title}</span>
              </div>
            ))}
            </Slider>
        ) : (
          <div className={Styles.list}>
            {itensListSortedByTitle.map(item => (
              <div className={`${Styles.listItem} ${item.id === itemIdActive ? Styles.active : ''}`}
                tabIndex={0}
                key={item.id}
                role='button'
                id={`list_item_${item.id}`}
                onClick={() => handleChangeItemActive(item.id)}
                onKeyUp={(e) => handleKeyUp(e, item.id)}
              >
                <span>{item.title}</span>
              </div>
            ))}

            <div className={Styles.listItemIndicatorActive}
              style={{
                top: `calc(${indicatorPosition} * ${LIST_ITEM_HEIGHT_DESK}px)`,
                height: `${LIST_ITEM_HEIGHT_DESK}px`
              }}
            ></div>
          </div>
        )}

        <div className={Styles.content}>

          <div className={Styles.contentText}>
            <h5>{itemActive?.title}</h5>
            <span>{t('home.ourIndustries.useCases')}</span>
            <p>{itemActive?.text}</p>

            {/* <a href="#">more details</a> */}

            <div className={Styles.contentTextLogo}>
              <img src={itemActive.logo} alt="" />
            </div>
          </div>

          <div className={Styles.imgUseCaseContainer}>
            <img className={Styles.imgUseCase} src={itemActive.src} alt="" />
          </div>

        </div>
      </div>

      <div className={Styles.imgUseCaseFixContainer}>
        <img className={Styles.imgUseCaseFix} src={ArtHomeUseCase} alt="" />
      </div>
    </section>
  )
}
