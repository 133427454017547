import React from 'react'
import Styles from '../css/css-components/buttonPrimaryOutline.module.css'
import { useLocation } from 'react-router-dom'

function ButtonPrimaryOutline({ title }) {
    const location = useLocation()

    const isOutlineBlack =
        location.pathname === '/' ||
        location.pathname.includes('manuals')

    return (
        <button
            className={`
                ${Styles.buttonPrimaryOutline}
                ${isOutlineBlack
                    ? Styles.buttonPrimaryOutlineHome
                    : Styles.buttonPrimaryOutlineNotHome
                }
            `}
        >
            {title}
        </button>
    )
}

export default ButtonPrimaryOutline
