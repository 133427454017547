import React, { useState, useEffect } from "react";
import Topbar from "../Components/Topbar";
import Share from "../Components/Share";
import Footer from "../Components/Footer";

import BenefitsImage from "../Img/Default/art-benefits.svg";
import TravelTransportationUsecase from "../Img/Default/usecase.svg";
import StyleTemplate from "../css/css-templates/markets-template.module.css";
import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
// import Scrollbar from "smooth-scrollbar";
import { useTranslation } from "react-i18next";

function TravelTransportation({ onScroll }) {
  const { t } = useTranslation();
  const [numScroll, setNumScroll] = useState(30);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (window.outerWidth > 1400) {
      var options = {
        damping: 0.06,
        alwaysShowTracks: true,
      };
      // Scrollbar.init(document.querySelector(".main"), options);
      // Scrollbar.getAll()[0].track.xAxis.element.remove()
      // Scrollbar.getAll()[0].addListener((e) => {
      //     if (e.offset.y > 70) {
      //         setNumScroll(53)
      //     } else {
      //         setNumScroll(30)
      //     }
      // })

      // return () => { Scrollbar.destroyAll() }
    } else {
      let observadorEl = new IntersectionObserver(
        function (entries) {
          if (entries[0].isIntersecting) {
            setNumScroll(30);
          } else {
            setNumScroll(53);
          }
        },
        {
          threshold: [0.0],
        }
      );

      //iniciando o opservador para #myDiv
      observadorEl.observe(document.querySelector("#targetElement"));
    }
  }, []);

  useEffect(() => {
    let pageLargura = window.outerWidth < 500 ? 480 : 700;

    var observador = new IntersectionObserver(
      function (entries) {
        entries.forEach(function (entry) {
          //loop em todos os elementos atingidos
          const { isIntersecting, intersectionRatio } = entry;
          if (isIntersecting === true || intersectionRatio > 0) {
            entry.target.classList.add(StyleTemplate.active);
            observador.unobserve(entry.target); // se você só precisar do event uma vez utilize essa linha
          }
        });
      },
      {
        threshold: [0.5],
      }
    );

    //iniciando o opservador para #myDiv
    [...document.querySelectorAll(".animation")].forEach((cada) => {
      observador.observe(cada);
    });
  }, []);

  return (
    <>
      <Topbar
        color={"white"}
        backgroundColor="navy"
        accentColor="var(--base-blue)"
        barsColor="white"
        num={numScroll}
      />
      <div
        onScroll={onScroll}
        className={`${StyleTemplate.marketsTemplateContainer} ${StyleTemplate.fixed} main`}
      >
        <Helmet>
          <meta name="theme-color" content="#333366" />
          <title>{t('travel.title')} - Group Link</title>
        </Helmet>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "60px",
            background: "var(--base-navy)",
          }}
          id="targetElement"
        ></div>
        <div className={StyleTemplate.marketsTemplate}>
          <div className={StyleTemplate.marketsTemplateTitleContainer}>
            <div className={StyleTemplate.marketsTemplatePageTitle}>
              {t('travel.title')}
            </div>
          </div>

          <div
            className={`${StyleTemplate.marketsTemplateIntroContainer} ${StyleTemplate.marketsTemplateTravelImage}`}
          >
            <div className={StyleTemplate.overlayBlue}></div>

            <div className={StyleTemplate.marketsTemplateIntroTop}>
              <div className={StyleTemplate.marketsTemplateIntroNavigation}>
                <Link to="/" className={StyleTemplate.marketsTemplateIntroLink}>
                  {t('travel.industries')}
                </Link>
                {` > `}
                <Link
                  to="/TravelTransportation"
                  className={StyleTemplate.marketsTemplateIntroLink}
                >
                  {t('travel.title')}
                </Link>
              </div>
              <div className={StyleTemplate.marketsTemplateIntroTitle}>
                {t('travel.introTitle')}
              </div>
              <div className={StyleTemplate.marketsTemplateIntroDescription}>
                {t('travel.introDesc')}
              </div>
            </div>
            <div className={StyleTemplate.marketsTemplateIntroMiddle}>
              <div className={StyleTemplate.marketsTemplateIntroMiddleTop}>
                <div
                  className={StyleTemplate.marketsTemplateIntroMiddleTopTexts}
                >
                  <div
                    className={`${StyleTemplate.marketsTemplateIntroMiddleTopText} animation`}
                  >
                    {t('travel.introMiddleTopText')}
                  </div>
                  <div
                    className={`${StyleTemplate.marketsTemplateIntroMiddleTopText} animation`}
                  >
                    {t('travel.introMiddleTopTextTwo')}
                  </div>
                </div>
                <div
                  className={`${StyleTemplate.marketsTemplateIntroMiddleTopTitle} animation`}
                >
                  {t('travel.benefits')}
                </div>
              </div>
              <div className={StyleTemplate.marketsTemplateMiddleBottom}>
                <div
                  className={StyleTemplate.marketsTemplateMiddleBottomOptions}
                >
                  <div
                    className={`${StyleTemplate.marketsTemplateMiddleBottomOption} animation`}
                  >
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionTitle
                      }
                    >
                      {t('travel.impacts')}
                    </div>
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionText
                      }
                    >
                      {t('travel.thePositive')}
                    </div>
                  </div>

                  <div
                    className={`${StyleTemplate.marketsTemplateMiddleBottomOption} animation`}
                  >
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionTitle
                      }
                    >
                      {t('travel.extensible')}
                    </div>
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionText
                      }
                    >
                      {t('travel.agileInfra')}
                    </div>
                  </div>

                  <div
                    className={`${StyleTemplate.marketsTemplateMiddleBottomOption} animation`}
                  >
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionTitle
                      }
                    >
                      {t('travel.security')}
                    </div>
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionText
                      }
                    >
                      {t('travel.sendingInfo')}
                    </div>
                  </div>

                  <div
                    className={`${StyleTemplate.marketsTemplateMiddleBottomOption} animation`}
                  >
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionTitle
                      }
                    >
                      {t('travel.compact')}
                    </div>
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionText
                      }
                    >
                      {t('travel.theKind')}
                    </div>
                  </div>

                  <div
                    className={`${StyleTemplate.marketsTemplateMiddleBottomOption} animation`}
                  >
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionTitle
                      }
                    >
                      {t('travel.versatile')}
                    </div>
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionText
                      }
                    >
                      {t('travel.technologyCanChange')}
                    </div>
                  </div>

                  <div
                    className={`${StyleTemplate.marketsTemplateMiddleBottomOption} animation`}
                  >
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionTitle
                      }
                    >
                      {t('travel.globalSupport')}
                    </div>
                    <div
                      className={
                        StyleTemplate.marketsTemplateMiddleBottomOptionText
                      }
                    >
                      {t('travel.allDevices')}
                    </div>
                  </div>
                </div>

                <div
                  className={`${StyleTemplate.marketsTemplateMiddleBottomImageContainer} animation`}
                >
                  <img
                    src={BenefitsImage}
                    className={StyleTemplate.marketsTemplateMiddleBottomImage}
                  />
                </div>
              </div>
            </div>
            <Share />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default TravelTransportation;
