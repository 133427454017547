import Styles from '../css/css-components/headquarters-component.module.css'

const Headquarters = ({ office }) => {
  const email = "contact@grouplinknetwork.com"
  return (
    <>
      <hr className={Styles.row}/>
    <ul className={Styles.headquarters}>
      <li className={Styles.defaultHeadquarter}>
        <h4>
          USA Headquarters
        </h4>
        <a href={`mailto:${email}`}>{email}</a>
      </li>
      {office.map((offices) => (
        <li className={Styles.listItem}>
          <h4>{offices.title}</h4>
          <a href={`mailto:${offices.emai}`}>{offices.email}</a>
        </li>
      ))}
    </ul>
    </>
  );
};

export default Headquarters;
