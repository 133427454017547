import React, { Component } from 'react'
import Logo from '../Img/Default/logo-main-light.svg'
import LinkedinLogo from '../Img/Default/icon-linkedin.svg'
import InstagramLogo from '../Img/Default/icon-instagram.svg'
import YoutubeLogo from '../Img/Default/icon-youtube.svg'
import SeloIbracemLogo from '../Img/Default/selo-ibracem.png'
// import { Link, Route } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import '../css/Footer.css'

import Styles from '../css/css-components/footer-component.module.css'
import { useTranslation } from 'react-i18next'

function Footer() {
    let year = new Date().getFullYear()
    const { t } = useTranslation()

    return (
        <div className={Styles.footerContainer}>
            <div className={Styles.footer}>
                <div className={Styles.footerTopContainer}>
                    <div className={Styles.footerBrandContainer}>
                        <div className={Styles.footerBrandContent}>
                            <img className={Styles.footerBrandImage} src={Logo} alt='Grouplink Logo' />
                            <div className={Styles.footerBrandName}>Transcend IoT</div>
                        </div>

                        <div className={Styles.footerCopyrightSocialContainer}>
                            <a href='https://www.instagram.com/grouplink.one' target='_blank' rel="noreferrer">
                                <img className={Styles.footerCopyrightSocialImage} src={InstagramLogo} alt='Logo Instagram' />
                            </a>

                            <a href='https://www.linkedin.com/company/grouplinktech/' target='_blank' rel="noreferrer">
                                <img className={Styles.footerCopyrightSocialImage} src={LinkedinLogo} alt='Logo Linkedin' />
                            </a>

                            <a href='https://www.youtube.com/@grouplinktech' target='_blank' rel="noreferrer">
                                <img className={Styles.footerCopyrightSocialImage} src={YoutubeLogo} alt='Logo Youtube' />
                            </a>
                        </div>
                    </div>

                    <hr />

                    <div className={Styles.footerOptionsContainer}>
                        <div className={Styles.footerOptions}>
                            <div className={Styles.footerOptionTitle}>{t('footer.technology')}</div>
                            <div className={Styles.footerOptionsItems}>
                                <Link to='/Technology/#overview'>
                                    <div className={Styles.footerOptionsItem}>
                                        {t('footer.technology_overview')}
                                    </div>
                                </Link>

                                <Link to='/Technology/#benefits'>
                                    <div className={Styles.footerOptionsItem}>
                                        {t('footer.technology_benefits')}
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className={Styles.footerOptions}>
                            <div className={Styles.footerOptionTitle}>{t('footer.solutions')}</div>
                            <div className={Styles.footerOptionsItems}>
                                <Link to='/SmartRetail'>
                                    <div className={Styles.footerOptionsItem}>
                                        GLSmartRetail
                                    </div>
                                </Link>
                                <Link to='/Smartplaces'>
                                    <div className={Styles.footerOptionsItem}>
                                        GLSmartPlaces
                                    </div>
                                </Link>
                                <Link to='/Tracker'>
                                    <div className={Styles.footerOptionsItem}>
                                        GLTracker
                                    </div>
                                </Link>
                                <Link to='/Utilities'>
                                    <div className={Styles.footerOptionsItem}>
                                        GLUtilities
                                    </div>
                                </Link>
                                {/* <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        GLAntifraud
                                    </div>
                                </Link>
                                <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        GLMicroInteractions
                                    </div>
                                </Link> */}
                            </div>
                        </div>
                        {/* <div className={Styles.footerOptions}>
                            <div className={Styles.footerOptionTitle}>Devices</div>
                            <div className={Styles.footerOptionsItems}>
                                <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        GLStationWifi
                                    </div>
                                </Link>
                                <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        GLPlug
                                    </div>
                                </Link>
                                <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        GLCard
                                    </div>
                                </Link>
                                <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        GLPet
                                    </div>
                                </Link>
                            </div>
                        </div> */}
                        <div className={Styles.footerOptions}>
                            <div className={Styles.footerOptionTitle}>{t('footer.about')}</div>
                            <div className={Styles.footerOptionsItems}>
                                {/* <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        Blog
                                    </div>
                                </Link>
                                <div className={Styles.footerOptionsItem}>
                                    Company
                                </div> */}
                                <Link to='/Contact'>
                                    <div className={Styles.footerOptionsItem}>
                                        {t('footer.getInTouch')}
                                    </div>
                                </Link>
                                {/* <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        Case Studies
                                    </div>
                                </Link>
                                <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        Partners
                                    </div>
                                </Link>
                                <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        Client List
                                    </div>
                                </Link>
                                <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        Careers
                                    </div>
                                </Link> */}
                            </div>
                        </div>

                        <div className={Styles.footerOptions}>
                            <div className={Styles.footerOptionTitle}>{t('footer.developers')}</div>
                            <div className={Styles.footerOptionsItems}>
                                <a href='https://dashboard.grouplinknetwork.com/' target="_blank" rel="noreferrer">
                                    <div className={Styles.footerOptionsItem}>
                                        Dashboard
                                    </div>
                                </a>
                                {/* <div className={Styles.footerOptionsItem}>
                                    Development Kit
                                </div>
                                <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        API Documentation
                                    </div>
                                </Link>
                                <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        Downloads
                                    </div>
                                </Link> */}
                            </div>
                        </div>

                        <div className={Styles.footerOptions}>
                            <div className={Styles.footerOptionTitle}>{t('footer.support')}</div>
                            <div className={Styles.footerOptionsItems}>
                                <Link to='/static/manuals'>
                                    <div className={Styles.footerOptionsItem}>
                                        {t('footer.manuals')}
                                    </div>
                                </Link>
                                {/* <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        Security
                                    </div>
                                </Link>
                                <Link to='/'>
                                    <div className={Styles.footerOptionsItem}>
                                        Resources
                                    </div>
                                </Link> */}
                            </div>
                        </div>

                        <div className={Styles.footerOptions}>
                            <a href='https://dashboard.grouplinknetwork.com/' target="_blank" rel="noreferrer" >
                                <div className={Styles.footerOptionLink}>Log In</div>
                            </a>
                        </div>

                        <div className={Styles.footerImg}>
                            <img src={SeloIbracemLogo} alt="Logo Ibracem" />
                        </div>
                    </div>
                </div>

                <hr />

                <div className={Styles.footerBottomContainer}>
                    <div className={Styles.footerCopyrightContainer}>
                        <div className={Styles.footerCopyrightLeftContainer}>
                            <div className={Styles.footerCopyrightText}>© {year} Group Link™ Technologies Inc.</div>
                            <div className={Styles.footerCopyrightOptions}>
                                {/* <Link to='/'>
                                    <div className={Styles.footerCopyrightOption}>Cookies</div>
                                </Link> */}
                                <a href='https://utilities.grouplinkone.com/privacy' target='_blank' rel="noreferrer">
                                    <div className={Styles.footerCopyrightOption}>{t('footer.privacy')}</div>
                                </a>
                                <a href='https://utilities.grouplinkone.com/terms' target='_blank' rel="noreferrer">
                                    <div className={Styles.footerCopyrightOption}>{t('footer.terms')}</div>
                                </a>
                                {/* <a href='https://dashboard.grouplinknetwork.com/needhelp'>
                                    <div className={Styles.footerCopyrightOption}>
                                        {t('footer.helpCenter')}
                                    </div>
                                </a> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
