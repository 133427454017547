import { Box } from "@mui/material";
import { gsap } from "gsap";
import { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const WriteText = ({ text, className, preservePointer, mainTL, opts = {} }) => {
  const textRef = useRef();

  const [tl, setTL] = useState();
  const { i18n } = useTranslation();


  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      setTL(gsap.timeline({ ...opts }));
    });
    return () => ctx.revert();
  }, [i18n.language]);

  useLayoutEffect(() => {
    if (!tl) return;
    const stagger = 0.1;
    const duration = stagger / 10;

    tl.from(gsap.utils.toArray(textRef.current.childNodes), {
      duration: duration,
      opacity: 0,
      stagger: stagger,
    });

    const cursors = gsap.utils.toArray(`.${className} > *`);
    tl.to(
      cursors,
      { opacity: 0, stagger: stagger, duration: duration },
      `<${duration + stagger}`
    );
    tl.fromTo(
      cursors.slice(-1),
      { opacity: 1 },
      {
        opacity: 0,
        duration: duration,
        repeat: preservePointer ? -1 : 0,
        ease: "power4.in",
        repeatDelay: 0.4,
        yoyo: true,
      }
    );
    mainTL.add(tl);
  }, [tl, mainTL]);

  return (
    <Box
      ref={textRef}
      sx={{
        overflow: "hidden",
        mr: 15,
        width: "100%",
        display: "flex",
      }}
    >
      {text.split("").map((word, index) => (
        <Box
          key={index}
          className={className}
          sx={{
            fontSize: "70px",
            color: "var(--base-navy)",
            whiteSpace: "nowrap",
            p: word === " " ? 1 : 0,
            position: "relative",
          }}
        >
          {word}
          <Box
            sx={{
              opacity: 1,
              position: "absolute",
              height: "0.8em",
              width: "0.1em",
              top: "0.2em",
              right: "-0.2em",
              backgroundColor: "var(--base-blue)",
              borderRadius: "10px",
            }}
          />
        </Box>
      ))}
    </Box>
  );
};
export default WriteText;
