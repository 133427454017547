export function  topbarAnimation(backgroundColor,setState){
    window.addEventListener('scroll',()=>{
        let topbar = document.querySelector("#topbar")

        if(topbar.classList.toggle("active",window.scrollY > 50)){
            setState({background:backgroundColor})
        }else{
            setState({background:"transparent"})
        }

    })
}

export const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
