import React, { useState } from 'react'
import Styles from '../css/css-components/share-component.module.css'
import { sharePost } from '../services/TimeService'
import MessageForm from "../Components/MessageForm"
import {
    GL_EMAIL_SENT_MESSAGE
    , GL_EMAIL_WAS_NOT_SENT_MESSAGE
} from '../message-constants';
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useTranslation } from 'react-i18next';
import * as yup from "yup";
import ButtonPrimary from './ButtonPrimary';

const InitialValue = {
    first_name: undefined,
    email: undefined,
    phone: undefined,
    description: undefined,
}

const MAX_LENGTH_DESCRIPTION = 1000

function Share() {
    const [values, setValues] = useState(InitialValue);
    const [message, setMessage] = useState({ status: true, msg: GL_EMAIL_SENT_MESSAGE, color: "Inative", animation: false, a: true });
    const [userInput, setUserInput] = useState("")
    const [disabled, setDisabled] = useState(false)

    const { t, i18n } = useTranslation();

    const formTechnologySchema = yup.object().shape({
        first_name: yup.string().required(t('formTechnology.first_name')),
        last_name: yup.string().required(t('formTechnology.last_name')),
        email: yup.string().required(t('formTechnology.email')).email(),
        phone: yup.string().required(t('formTechnology.phone')),
        description: yup.string().required(t('formTechnology.description')).min(10)
    });

    const handleChanged = (e) => {
        const value = e.target.value

        setUserInput(value)

        if (userInput.length > 1000) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formTechnologySchema),
    });

    function onSubmitFunction(data) {
        sharePost(data.first_name, data.last_name, data.email, data.phone, data.description)
            .then(response => {
                if (response.message === "ok") {
                    setMessage({ status: true, msg: GL_EMAIL_SENT_MESSAGE, color: "Green", animation: true })
                    setTimeout(() => {
                        setMessage({ status: true, msg: GL_EMAIL_SENT_MESSAGE, color: "Inative", animation: true })
                    }, 5000)
                    setValues(InitialValue)
                } else {
                    setMessage({ status: true, msg: GL_EMAIL_WAS_NOT_SENT_MESSAGE, color: "Red", animation: true })
                    setTimeout(() => {
                        setMessage({ status: true, msg: GL_EMAIL_SENT_MESSAGE, color: "Inative", animation: true })
                    }, 5000)
                    setValues(InitialValue)
                }
            }).catch((err) => {
                setMessage({ status: true, msg: GL_EMAIL_WAS_NOT_SENT_MESSAGE, color: "Red", animation: true })
                setTimeout(() => {
                    setMessage({ status: true, msg: GL_EMAIL_SENT_MESSAGE, color: "Inative", animation: true })
                }, 5000)
            })
    }

    function formatTextCharactersRemaining() {
        const charactersRemaining = MAX_LENGTH_DESCRIPTION - userInput.length
        const remainingOne = charactersRemaining === 1
        const language = i18n.language

        if (language === "en") {
            return `characters ${charactersRemaining} remaining`
        } else {
            return `${charactersRemaining} caractere${!remainingOne ? 's' : ''} restante${!remainingOne ? 's' : ''}`
        }

    }

    return (
        <div className={Styles.shareContainer}>
            <div className={Styles.share}>
                <div className={Styles.shareTitle}>{t('share.title')}</div>
                <div className={Styles.shareFormContainer}>
                    <div className={Styles.shareFormText}>
                        {t('share.weWouldLove')}
                        <a href={t('share.sendUsEmailHref')}>
                            {t('share.sendUsAnEmail')}
                        </a>
                        {t('share.orFillOutTheForm')}
                    </div>

                    <form onSubmit={handleSubmit(onSubmitFunction)} >
                        <div className={Styles.shareForm}>
                            <div className={Styles.shareFormLeft}>
                                <div className={Styles.shareFormName}>
                                    <div>
                                        <div className={Styles.shareFormSubtitle}>{t('share.firstName')}</div>
                                        <input
                                            className={errors.first_name?.message ? Styles.alertBorder : Styles.shareFormInput}
                                            name='first_name'
                                            type='text'
                                            value={values.first_name}
                                            placeholder='John'
                                            {...register("first_name")}>
                                        </input>
                                        <span>{errors.first_name?.message}</span>
                                    </div>

                                    <div>
                                        <div className={Styles.shareFormSubtitle}>{t('share.lastName')}</div>
                                        <input
                                            className={errors.last_name?.message ? Styles.alertBorder : Styles.shareFormInput}
                                            name='last_name'
                                            type='text'
                                            value={values.last_name}
                                            placeholder='Johnson'
                                            {...register("last_name")}>
                                        </input>
                                        <span>{errors.last_name?.message}</span>
                                    </div>
                                </div>

                                <div className={Styles.shareFormLeftTextInput}>
                                    <div className={Styles.shareFormSubtitle}>
                                        {t('share.emailAddress')}
                                    </div>

                                    <input
                                        className={errors.email?.message ? Styles.alertBorder : Styles.shareFormInput}
                                        id="email"
                                        name='email'
                                        type='text'
                                        value={values.email}
                                        placeholder='john@company.com'
                                        {...register("email")}>
                                    </input>

                                    <span>{errors.email?.message}</span>
                                </div>

                                <div className={Styles.shareFormLeftTextInput}>
                                    <div className={Styles.shareFormSubtitle}>
                                        {t('share.phoneNumber')}
                                    </div>

                                    <Controller
                                        control={control}
                                        name="phone"
                                        rules={{ validate: (value) => isValidPhoneNumber(value) }}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                placeholder={t('share.phoneNumberPlaceholder')}
                                                defaultCountry={i18n.language === "en" ? "US" : "BR"}
                                                id="phone"
                                                className={errors.phone?.message ? Styles.alertBorder : Styles.shareFormInput}
                                            />
                                        )} />
                                    <span>{errors.phone?.message}</span>
                                </div>
                            </div>

                            <div className={Styles.shareFormRight}>
                                <div className={Styles.shareFormRightTextInput}>
                                    <div className={Styles.shareFormSubtitle}>
                                        <span>{t('share.description')}</span>
                                        {userInput === ""
                                            ? (
                                                <p className={Styles.inputUser}>max {MAX_LENGTH_DESCRIPTION} {t('share.character')}</p>
                                            ) : (
                                                <p className={userInput.length > MAX_LENGTH_DESCRIPTION ? Styles.inputUserLimit : Styles.inputUser}>
                                                    {formatTextCharactersRemaining()}
                                                </p>
                                            )
                                        }
                                    </div>

                                    <textarea
                                        className={errors.description?.message ? Styles.alertBorderTextArea : Styles.shareFormTextareaContainer}
                                        type="text"
                                        name='description'
                                        placeholder={t('share.descriptionPlaceholder')}
                                        value={values.description}
                                        {...register("description")}
                                        onChange={handleChanged}>
                                    </textarea>

                                    <span>{errors.description?.message}</span>
                                </div>

                                <ButtonPrimary
                                    title={t('share.submitIdea')}
                                    type="submit"
                                    disabled={disabled}
                                />
                                {/* <div className={Styles.shareFormButtonContainer}>
                                    <div className={Styles.shareFormButtonSize}>
                                        <button
                                            type="submit"
                                            disabled={disabled}
                                            className={errors.description?.message ? Styles.btnPrimary : 'btn-primary'}
                                        >
                                            {t('share.submitIdea')}
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </form>

                    <div className={Styles.shareFormMessage}>
                        <p>
                            <b>{t('share.allFieldsRequired')}</b> {t('share.formAlert')}
                        </p>
                    </div>
                </div>
            </div>
            {message.status && <MessageForm message={message.msg} color={message.color} animation={message.animation} />}
        </div>
    )
}

export default Share
