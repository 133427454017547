import React, { useState, useEffect } from "react";
import Topbar from "../Components/Topbar";
import Footer from "../Components/Footer";
import Benefits from "../Components/Benefits";
import StyleTemplate from "../css/css-templates/solutions-template.module.css";

import SmartRetailIntro from "../Img/SmartRetail/smart-girl.png";
import SmartRetailLogo from "../Img/SmartRetail/SmartRetailLogo.svg";
import SmartRetailUsecase from "../Img/Default/usecase.svg";
import SmartRetailPlug from "../Img/SmartRetail/plug@2x.png";
import SmartRetailStation from "../Img/SmartRetail/station@2x.png";
import Share from "../Components/Share";
import { Helmet } from "react-helmet";
import Scrollbar from "smooth-scrollbar";
import LocalizedStrings from "react-localization";
import { useTranslation } from "react-i18next";

function SmartRetail({ onScroll }) {
  const [numScroll, setNumScroll] = useState(30);
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    var options = {
      damping: 0.06,
      alwaysShowTracks: true,
    };
    //    Scrollbar.init(document.querySelector(".main"),options);
    //    Scrollbar.getAll()[0].track.xAxis.element.remove()
    //    Scrollbar.getAll()[0].addListener((e)=>{
    //        if(e.offset.y > 70){
    //            setNumScroll(53)
    //        }else{
    //            setNumScroll(30)
    //        }
    //    })

    //    return ()=>{Scrollbar.destroyAll()}
  }, []);

  useEffect(() => {
    const target = document.querySelectorAll("[data-anime]");
    const animationClass = StyleTemplate.active;

    function animeScroll(offset) {
      target.forEach((element) => {
        if (offset + 500 > element.offsetTop) {
          element.classList.add(animationClass);
        }
      });
    }

    animeScroll();

    // if (target.length) {
    //   Scrollbar.getAll()[0].addListener((e) => {
    //     animeScroll(e.offset.y);
    //   });
    // }
  }, []);

  return (
    <>
      <Topbar
        color={"white"}
        backgroundColor="white"
        barsColor="navy"
        num={numScroll}
      />
      <div
        onScroll={onScroll}
        className={`${StyleTemplate.solutionsTemplateContainer} ${StyleTemplate.fixed} main`}
      >
        <Helmet>
          <meta name="theme-color" content="#FFFFFE" />
          <title>Smart Retail - Group Link</title>
        </Helmet>

        <div className={StyleTemplate.solutionsTemplate}>
          <div className={StyleTemplate.solutionsTemplateTopIntroImgLeft}>
            <img
              src={SmartRetailIntro}
              className={StyleTemplate.solutionsTemplateTopIntroImage}
            />
            <div className={StyleTemplate.solutionsTemplateTopIntroContent}>
              <div>
                <img
                  src={SmartRetailLogo}
                  className={StyleTemplate.solutionsTemplateTopIntroLogoImage}
                />
                <div
                  className={
                    StyleTemplate.solutionsTemplateTopIntroSolutionDescription
                  }
                >
                  {t('smartRetail.introTitle')}
                </div>
              </div>
            </div>
          </div>

          <div className={StyleTemplate.solutionsTemplatePointsContainer}>
            <div
              data-anime="true"
              className={StyleTemplate.solutionsTemplatePoint}
            >
              <div
                className={`${StyleTemplate.solutionsTemplatePointIcon} gl-target`}
              />
              <div className={StyleTemplate.solutionsTemplatePointTitle}>
                {t('smartRetail.noLocation')}
              </div>
              <div className={StyleTemplate.solutionsTemplatePointText}>
                {t('smartRetail.smartRetailDevices')}
              </div>
            </div>

            <div
              data-anime="true"
              className={StyleTemplate.solutionsTemplatePoint}
            >
              <div
                className={`${StyleTemplate.solutionsTemplatePointIcon} gl-plug`}
              />
              <div className={StyleTemplate.solutionsTemplatePointTitle}>
                {t('smartRetail.plugAndPlay')}
              </div>
              <div className={StyleTemplate.solutionsTemplatePointText}>
                {t('smartRetail.smartRetailSimple')}
              </div>
            </div>

            <div
              data-anime="true"
              className={StyleTemplate.solutionsTemplatePoint}
            >
              <div
                className={`${StyleTemplate.solutionsTemplatePointIcon} gl-puzzle`}
              />
              <div className={StyleTemplate.solutionsTemplatePointTitle}>
                {t('smartRetail.compat')}
              </div>
              <div className={StyleTemplate.solutionsTemplatePointText}>
                {t('smartRetail.usingThe')}
              </div>
            </div>
          </div>

          <div className={StyleTemplate.solutionTemplateUseCaseContainer}>
            <div
              data-anime="true"
              className={StyleTemplate.solutionTemplateUseCaseContent}
            >
              <div
                className={StyleTemplate.solutionTemplateUseCaseContentTitle}
              >
                {t('smartRetail.case')}
              </div>
              <div className={StyleTemplate.solutionTemplateUseCaseContentText}>
                {t('smartRetail.establishment')}
                <br />
                <br />
                {t('smartRetail.allBased')}
              </div>
            </div>

            <img
              data-anime="true"
              className={StyleTemplate.solutionTemplateUseCaseImage}
              src={SmartRetailUsecase}
            />
          </div>

          <div className={StyleTemplate.solutionsTemplateDevicePackContainer}>
            <div
              data-anime="true"
              className={
                StyleTemplate.solutionsTemplateDevicePackTitleContainer
              }
            >
              <div className={StyleTemplate.solutionsTemplateDevicePackTitle}>
                {t('smartRetail.devicePack')}
              </div>
              <div
                className={StyleTemplate.solutionsTemplateDevicePackDescription}
              >
                {t('smartRetail.basicSet')}
              </div>
            </div>

            <div className={StyleTemplate.solutionsTemplateDevicePackContent}>
              <div
                className={`${StyleTemplate.solutionsTemplateDevicePackItem} ${StyleTemplate.solutionsTemplateDevicePackContent1}`}
              >
                <img
                  data-anime="true"
                  // className={StyleTemplate.solutionsTemplateDevicePackImage}
                  src={SmartRetailStation}
                />
                <div className={StyleTemplate.solutionsTemplateDevicePackName}>
                  <span>GL</span>Station
                </div>
              </div>

              <div
                className={`${StyleTemplate.solutionsTemplateDevicePackItem} ${StyleTemplate.solutionsTemplateDevicePackContent2}`}
              >
                <img
                  data-anime="true"
                  // className={StyleTemplate.solutionsTemplateDevicePackImage}
                  src={SmartRetailPlug}
                />
                <div className={StyleTemplate.solutionsTemplateDevicePackName}>
                  <span>GL</span>Plug
                </div>
              </div>
            </div>
          </div>

          <Share />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default SmartRetail;
