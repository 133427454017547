import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TypeAnimation } from 'react-type-animation';

export function WriteTextTwo({ titleOne, titleIoio, titleTwo, className }) {
  const CURSOR_CLASS_NAME = 'custom-type-animation-cursor';


  return (
    <TypeAnimation
      sequence={[
        // Same substring at the start will only be typed out once, initially
        // `${t('home.banner.titleOne')}\n${t('home.banner.titleIoio')}`,
        (el) => el.classList.add(CURSOR_CLASS_NAME),
        `${titleOne}\n${titleIoio}`,
        300,
        // `${t('home.banner.titleOne')}\n${t('home.banner.titleTwo')}`,
        `${titleOne}\n${titleTwo}`,
        300
      ]}
      className={className}
      wrapper='p'
      speed={30}
      style={{
        display: 'inline-block',
        whiteSpace: 'pre-line',
        color: '#333366',
      }}
    />
  )
}
