import React, { useState, useEffect } from "react";
import SmartPlacesIntroImage from "../Img/SmartPlaces/smartplaces-intro.png";
import SmartPlacesIntroLogo from "../Img/SmartPlaces/smartplaces-logo.svg";
import SmartPlacesPlug from "../Img/SmartPlaces/plug@2x.png";
import SmartPlacesStation from "../Img/SmartPlaces/station@2x.png";
import SmartPlacesCard from "../Img/SmartPlaces/smartplaces-card.png";
import SmartPlacesUsecase from "../Img/SmartPlaces/usecase-smartplaces.svg";
import SmartPlacesCard2 from "../Img/SmartPlaces/card@2x.png";
import Topbar from "../Components/Topbar";
import Footer from "../Components/Footer";
import Share from "../Components/Share";
import StyleTemplate from "../css/css-templates/solutions-template.module.css";
import { Helmet } from "react-helmet";
// import Scrollbar from "smooth-scrollbar";
import LocalizedStrings from "react-localization";
import { useTranslation } from "react-i18next";

function SmartPlaces({ onScroll }) {
  const { t } = useTranslation();
  const [numScroll, setNumScroll] = useState(30);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    var options = {
      damping: 0.06,
      alwaysShowTracks: true,
    };
    // Scrollbar.init(document.querySelector(".main"), options);
    // Scrollbar.getAll()[0].track.xAxis.element.remove()
    // Scrollbar.getAll()[0].addListener((e) => {
    //     if (e.offset.y > 70) {
    //         setNumScroll(53)
    //     } else {
    //         setNumScroll(30)
    //     }
    // })

    // return () => { Scrollbar.destroyAll() }
  }, []);

  useEffect(() => {
    const target = document.querySelectorAll("[data-anime]");
    const animationClass = StyleTemplate.active;

    function animeScroll(offset) {
      target.forEach((element) => {
        if (offset + 500 > element.offsetTop) {
          element.classList.add(animationClass);
        }
      });
    }

    animeScroll();

    // if (target.length) {
    //   Scrollbar.getAll()[0].addListener((e) => {
    //     animeScroll(e.offset.y);
    //   });
    // }
  }, []);

  return (
    <div>
      <Topbar
        color={"white"}
        backgroundColor="white"
        barsColor="navy"
        num={numScroll}
      />
      <div
        onScroll={onScroll}
        className={`${StyleTemplate.solutionsTemplateContainer} ${StyleTemplate.fixed} main`}
      >
        <Helmet>
          <meta name="theme-color" content="#FFFFFE" />
          <title>{t('smartPlaces.title')} - Group Link</title>
        </Helmet>

        <div className={StyleTemplate.solutionsTemplate}>
          <div className={StyleTemplate.solutionsTemplateTopIntroImgRight}>
            <div className={StyleTemplate.solutionsTemplateTopIntroContent}>
              <div>
                <img
                  src={SmartPlacesIntroLogo}
                  className={StyleTemplate.solutionsTemplateTopIntroLogoImage}
                />
                <div
                  className={
                    StyleTemplate.solutionsTemplateTopIntroSolutionDescription
                  }
                >
                  {t('smartPlaces.introTitle')}
                </div>
              </div>
            </div>
            <img
              src={SmartPlacesIntroImage}
              className={StyleTemplate.solutionsTemplateTopIntroImage}
            />
          </div>

          <div className={StyleTemplate.solutionsTemplatePointsContainer}>
            <div
              data-anime="true"
              className={StyleTemplate.solutionsTemplatePoint}
            >
              <div
                className={`${StyleTemplate.solutionsTemplatePointIcon} gl-compass`}
              />
              <div className={StyleTemplate.solutionsTemplatePointTitle}>
                {t('smartPlaces.realTime')}
              </div>
              <div className={StyleTemplate.solutionsTemplatePointText}>
                {t('smartPlaces.wirelessConnectivity')}
              </div>
            </div>

            <div
              data-anime="true"
              className={StyleTemplate.solutionsTemplatePoint}
            >
              <div
                className={`${StyleTemplate.solutionsTemplatePointIcon} gl-pin`}
              />
              <div className={StyleTemplate.solutionsTemplatePointTitle}>
                {t('smartPlaces.indoor')}
              </div>
              <div className={StyleTemplate.solutionsTemplatePointText}>
                {t('smartPlaces.trackPeople')}
              </div>
            </div>

            <div
              data-anime="true"
              className={StyleTemplate.solutionsTemplatePoint}
            >
              <div
                className={`${StyleTemplate.solutionsTemplatePointIcon} gl-share`}
              />
              <div className={StyleTemplate.solutionsTemplatePointTitle}>
                {t('smartPlaces.contactTracing')}
              </div>
              <div className={StyleTemplate.solutionsTemplatePointText}>
                {t('smartPlaces.theseProducts')}
              </div>
            </div>
          </div>

          <div className={StyleTemplate.solutionTemplateUseCaseContainer}>
            <div
              data-anime="true"
              className={StyleTemplate.solutionTemplateUseCaseContent}
            >
              <div
                className={StyleTemplate.solutionTemplateUseCaseContentTitle}
              >
                {t('smartPlaces.case')}
              </div>
              <div className={StyleTemplate.solutionTemplateUseCaseContentText}>
                {t('smartPlaces.canBeUsed')}
                <br />
                <br />
                {t('smartPlaces.finally')}
              </div>
            </div>
            <img
              data-anime="true"
              className={StyleTemplate.solutionTemplateUseCaseImage}
              src={SmartPlacesUsecase}
            />
          </div>

          <div className={StyleTemplate.solutionsTemplateDevicePackContainer}>
            <div
              data-anime="true"
              className={
                StyleTemplate.solutionsTemplateDevicePackTitleContainer
              }
            >
              <div className={StyleTemplate.solutionsTemplateDevicePackTitle}>
                {t('smartPlaces.devicePack')}
              </div>
              <div
                className={StyleTemplate.solutionsTemplateDevicePackDescription}
              >
                {t('smartPlaces.basicSet')}
              </div>
            </div>
            <div className={StyleTemplate.solutionsTemplateDevicePackContent}>
              <div
                className={`${StyleTemplate.solutionsTemplateDevicePackItem} ${StyleTemplate.solutionsTemplateDevicePackContent1}`}
              >
                <img
                  data-anime="true"
                  className={StyleTemplate.solutionsTemplateDevicePackImage}
                  src={SmartPlacesStation}
                />
                <div className={StyleTemplate.solutionsTemplateDevicePackName}>
                  <span>GL</span>Station
                </div>
              </div>
              <div
                className={`${StyleTemplate.solutionsTemplateDevicePackItem} ${StyleTemplate.solutionsTemplateDevicePackContent2}`}
              >
                <img
                  data-anime="true"
                  className={StyleTemplate.solutionsTemplateDevicePackImage}
                  src={SmartPlacesPlug}
                />
                <div className={StyleTemplate.solutionsTemplateDevicePackName}>
                  <span>GL</span>Plug
                </div>
              </div>
              <div
                className={`${StyleTemplate.solutionsTemplateDevicePackItem} ${StyleTemplate.solutionsTemplateDevicePackContent5}`}
              >
                <img
                  data-anime="true"
                  className={StyleTemplate.solutionsTemplateDevicePackImage}
                  src={SmartPlacesCard2}
                />
                <div className={StyleTemplate.solutionsTemplateDevicePackName}>
                  <span>GL</span>Card
                </div>
              </div>
            </div>
          </div>
          <Share />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default SmartPlaces;
