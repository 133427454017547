import React, { useState, useEffect } from "react";
import Topbar from "../Components/Topbar.js";
import Footer from "../Components/Footer.js";

import Style from "../css/css-pages/technology.module.css";
import TopArt from "../Img/Technology/art-technology01.svg";
import TopArtDesk from "../Img/Technology/art-technology01-desk.svg";
import benefitsArt from "../Img/Technology/art-benefits.svg";
import GraphOverview from "../Img/Technology/graph-overview.svg";
import GraphOverviewDesk from "../Img/Technology/graph-overview-desk.svg";
import TechData from "../Img/Technology/tech-datamobile.png";
import TechDataDesk from "../Img/Technology/tech-data.svg";
import HowItWorks from "../Img/Technology/howitworks.svg";
import Share from "../Components/Share.js";
import { Helmet } from "react-helmet";
import Delete from "../Img/Technology/icon-delete.svg";
// import Scrollbar from "smooth-scrollbar"
import BgVideo from "../Img/Technology/bg-tech-video.mp4";
import TechArtHeader from "../Img/Technology/ilustra-tech.svg";
import { useTranslation } from "react-i18next";

const Header = () => {
  return (
    <div className={Style.techHeaderText2}>
      <h1>
        One <br />
        Technology
      </h1>
      <h2>
        Endless <br />
        Opportunities
      </h2>
      <img className={Style.desktop2} src={TopArtDesk} alt="gl" />
    </div>
  );
};

const Technology = ({ onScroll }) => {

  const [numScroll, setNumScroll] = useState(30);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let pageLargura = window.outerWidth < 500 ? 480 : 700;

    var observador = new IntersectionObserver(
      function (entries) {
        entries.forEach(function (entry) {
          //loop em todos os elementos atingidos
          const { isIntersecting, intersectionRatio } = entry;
          if (isIntersecting === true || intersectionRatio > 0) {
            // console.log(entry);
            entry.target.classList.add(Style.active);
            observador.unobserve(entry.target); // se você só precisar do event uma vez utilize essa linha
          }
        });
      },
      {
        threshold: [0.5],
      }
    );

    //iniciando o opservador para #myDiv
    [...document.querySelectorAll(".animation")].forEach((cada) => {
      observador.observe(cada);
    });
  }, []);

  useEffect(() => {
    if (window.outerWidth > 1400) {
      var options = {
        damping: 0.06,
        alwaysShowTracks: false,
      };
      // Scrollbar.init(document.querySelector(".main"), options);
      // Scrollbar.getAll()[0].track.xAxis.element.remove()
      // Scrollbar.getAll()[0].addListener((e) => {
      //     if (e.offset.y > 70) {
      //         setNumScroll(53)
      //     } else {
      //         setNumScroll(30)
      //     }
      // })
      // return () => { Scrollbar.destroyAll() }
    } else {
      let observadorEl = new IntersectionObserver(
        function (entries) {
          if (entries[0].isIntersecting) {
            setNumScroll(30);
          } else {
            setNumScroll(53);
          }
        },
        {
          threshold: [1.0],
        }
      );
      //iniciando o opservador para #myDiv
      observadorEl.observe(document.querySelector("#targetElement"));
    }
  }, []);

  return (
    <>
      <Topbar
        color="white"
        backgroundColor="navy"
        accentColor="var(--base-blue)"
        barsColor="white"
        num={numScroll}
      />
      <main
        onScroll={onScroll}
        className="main"
        style={{ maxWidth: "100vw", maxHeight: "100vh", overflow: "auto" }}
      >
        <Helmet>
          <meta name="theme-color" content="#333366" />
          <title>{t('technology.title')} - Group Link</title>
        </Helmet>
        <div
          style={{ position: "relative", width: "0", height: "0" }}
          id="targetElement"
        ></div>

        <div className={Style.techTopbar}></div>

        <section className={Style.techHeader}>
          <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
            <video
              style={{ objectFit: "cover", borderRadius: "0 0 140px 0" }}
              height="100%"
              width="100%"
              autoPlay
              loop
              muted
            >
              <source src={BgVideo} type="video/mp4" />
            </video>
            <div className={Style.ilustraDiv}>
              <img className={Style.ilustraImg} src={TechArtHeader} />
            </div>
          </div>
        </section>

        <section className={Style.techOverview} id="overview">
          <div className={`${Style.techOverviewText} animation`}>
            <h1>{t('technology.overview')}</h1>
            <div>
              <p>{t('technology.welcome')}</p>
              <p>{t('technology.sensorsCloud')}</p>
              <p>{t('technology.ourExpertise')}</p>
            </div>
            <div>
              <p>{t('technology.oneDesigns')}</p>
              <p>{t('technology.letsExplore')}</p>
            </div>
          </div>
        </section>

        {/* <section className={Style.techWorks}>
                    <div className={Style.techWorksCont}>
                        <img src={HowItWorks} alt="image" />
                    </div>
                </section>
                <section className={Style.techWorksMobile}>
                    <div className={Style.techWorksContMobile}>
                        <img src={HowItWorks} alt="image" />
                    </div>
                </section> */}
        <section className={Style.techBenefits} id="benefits">
          <div className={`${Style.techBenefitsTop} animation`}>
            <div className={Style.techBenefitsTitle}>
              <h1>{t('technology.benefitsTextTitle')}</h1>
            </div>
            <div className={Style.techBenefitsGrid}>
              <div className={Style.techBenefitsBottomText}>
                <strong>{t('technology.accurate')}</strong>
                <p>{t('technology.weProvide')}</p>
              </div>

              <div className={Style.techBenefitsBottomText}>
                <strong>{t('technology.scalableGlobal')}</strong>
                <p>{t('technology.techUseSmartphones')}</p>
              </div>

              <div className={Style.techBenefitsBottomText}>
                <strong>{t('technology.versatile')}</strong>
                <p>{t('technology.highInfra')}</p>
              </div>

              <div className={Style.techBenefitsBottomText}>
                <strong>{t('technology.compact')}</strong>
                <p>{t('technology.ultraSmall')}</p>
              </div>
            </div>
          </div>

          <div className={`${Style.techBenefitsBottom} animation`}>
            <div className={Style.techBenefitsGrid}>
              {/* <div className={Style.techBenefitsBottomText}>
                                <strong>{t('technology.accurate}</strong>
                                <p>
                                    {t('technology.weProvide}
                                </p>
                            </div>

                            <div className={Style.techBenefitsBottomText}>
                                <strong>{localizatedStr.scalableGlobal}</strong>
                                <p>
                                    {localizatedStr.techUseSmartphones}
                                </p>
                            </div>

                            <div className={Style.techBenefitsBottomText}>
                                <strong>{localizatedStr.versatile}</strong>
                                <p>
                                    {localizatedStr.highInfra}
                                </p>
                            </div>

                            <div className={Style.techBenefitsBottomText}>
                                <strong>Compact</strong>
                                <p>
                                    {localizatedStr.ultraSmall}
                                </p>
                            </div> */}
            </div>

            <div className={`${Style.techBenefitsImage} animation`}>
              <img src={benefitsArt} alt="gl" />
            </div>

          </div>
        </section>

        <section className={Style.techBestPart}>
          <div className={`${Style.techBestPartCont} animation`}>
            <div className={Style.techBestPartContTitle}>
              <h1>{t('technology.bestPart')}</h1>
            </div>

            <div>
              <p>
                <span>{t('technology.weAreFully')}</span>{" "}
                {t('technology.thatsBecause')}
              </p>
              <p>{t('technology.ourJob')}</p>
            </div>
          </div>
          <div className={Style.image}>
            <img className={Style.imageMobile} src={TechData} alt="gl" />
            <img className={Style.imageDesk} src={TechDataDesk} alt="gl" />
          </div>
        </section>

        {/* <section className={Style.techWhyGl}>
                    <h1>{localizatedStr.why} Group Link<small>™</small> Technology?</h1>
                    <div className={Style.techWhyGlCont}>
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Group Link™</th>
                                    <th>LoRa</th>
                                    <th>Sigfox</th>
                                    <th>NB-IoT</th>
                                    <th>Beacon</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{localizatedStr.createdYears}</td>
                                    <td className={`gl-check ${Style.techCheckStyle}`} />
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                </tr>
                                <tr>
                                    <td>{localizatedStr.worldwide}</td>
                                    <td className={`gl-check ${Style.techCheckStyle}`} />
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                </tr>
                                <tr>
                                    <td>{localizatedStr.lowDevice}</td>
                                    <td className={`gl-check ${Style.techCheckStyle}`} />
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td className={`gl-check ${Style.techCheckStyle}`} />
                                </tr>
                                <tr>
                                    <td>{localizatedStr.dataPackage}</td>
                                    <td className={`gl-check ${Style.techCheckStyle}`} />
                                    <td className={`gl-check ${Style.techCheckStyle}`} />
                                    <td className={`gl-check ${Style.techCheckStyle}`} />
                                    <td className={`gl-check ${Style.techCheckStyle}`} />
                                    <td><img src={Delete} alt="delete" /></td>
                                </tr>
                                <tr>
                                    <td>{localizatedStr.softwareBased}</td>
                                    <td className={`gl-check ${Style.techCheckStyle}`} />
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                </tr>
                                <tr>
                                    <td>{localizatedStr.robustAnalytics}</td>
                                    <td className={`gl-check ${Style.techCheckStyle}`} />
                                    <td className={`gl-check ${Style.techCheckStyle}`} />
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                </tr>
                                <tr>
                                    <td>{localizatedStr.gdpr}</td>
                                    <td className={`gl-check ${Style.techCheckStyle}`} />
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td><img src={Delete} alt="delete" /></td>
                                    <td className={`gl-check ${Style.techCheckStyle}`} />
                                    <td><img src={Delete} alt="delete" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section> */}

        <section>
          <Share />
        </section>

        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Technology;
