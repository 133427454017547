import React, { useState, useEffect } from "react";
import Topbar from "../Components/Topbar";
import Footer from "../Components/Footer";
import Benefits from "../Components/Benefits";
import StyleTemplate from "../css/css-templates/solutions-template.module.css";
import Share from "../Components/Share";
import UtilitiesLogo from "../Img/Utilities/utilities-logo.svg";
import UtilitiesArt from "../Img/Utilities/utilities-art.png";
import Usecase from "../Img/Utilities/usecase-utilities.svg";
import SmartRetailPlug from "../Img/Utilities/plug@2x.png";
import SmartUtilitiesCard from "../Img/Utilities/card@2x.png";
import SmartUtilitiesUtilities from "../Img/Utilities/utilities@2x.png";
import { Helmet } from "react-helmet";
import Scrollbar from "smooth-scrollbar";
import LocalizedStrings from "react-localization";
import { useTranslation } from "react-i18next";

function Utilities({ onScroll }) {
  const { t } = useTranslation();

  const [numScroll, setNumScroll] = useState(30);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    var options = {
      damping: 0.06,
      alwaysShowTracks: true,
    };
    //    Scrollbar.init(document.querySelector(".main"),options);
    //    Scrollbar.getAll()[0].track.xAxis.element.remove()
    //    Scrollbar.getAll()[0].addListener((e)=>{
    //        if(e.offset.y > 70){
    //            setNumScroll(53)
    //        }else{
    //            setNumScroll(30)
    //        }
    //    })

    //    return ()=>{Scrollbar.destroyAll()}
  }, []);

  useEffect(() => {
    const target = document.querySelectorAll("[data-anime]");
    const animationClass = StyleTemplate.active;

    function animeScroll(offset) {
      target.forEach((element) => {
        if (offset + 500 > element.offsetTop) {
          element.classList.add(animationClass);
        }
      });
    }

    animeScroll();

    // if (target.length) {
    //   Scrollbar.getAll()[0].addListener((e) => {
    //     animeScroll(e.offset.y);
    //   });
    // }
  }, []);

  return (
    <>
      <Topbar
        color={"white"}
        backgroundColor="white"
        barsColor="navy"
        num={numScroll}
      />
      <div
        onScroll={onScroll}
        className={`${StyleTemplate.solutionsTemplateContainer} ${StyleTemplate.fixed} main`}
      >
        <Helmet>
          <meta name="theme-color" content="#fffffe" />
          <title>{t('utilities.title')} - Group Link</title>
        </Helmet>
        <div className={StyleTemplate.solutionsTemplate}>
          <div className={StyleTemplate.solutionsTemplateTopIntroImgRight}>
            <div className={StyleTemplate.solutionsTemplateTopIntroContent}>
              <div
                className={StyleTemplate.solutionsTemplateTopIntroRightContent}
              >
                <img
                  src={UtilitiesLogo}
                  className={StyleTemplate.solutionsTemplateTopIntroLogoImage}
                />
                <div
                  className={
                    StyleTemplate.solutionsTemplateTopIntroSolutionDescription
                  }
                >
                  {t('utilities.introTitle')}
                </div>
              </div>
            </div>
            <img
              src={UtilitiesArt}
              className={StyleTemplate.solutionsTemplateTopIntroImage}
            />
          </div>

          <div className={StyleTemplate.solutionsTemplatePointsContainer}>
            <div
              data-anime="true"
              className={StyleTemplate.solutionsTemplatePoint}
            >
              <div
                className={`${StyleTemplate.solutionsTemplatePointIcon} gl-shield`}
              />
              <div className={StyleTemplate.solutionsTemplatePointTitle}>
                {t('utilities.operationalEfficience')}
              </div>
              <div className={StyleTemplate.solutionsTemplatePointText}>
                {t('utilities.optimazedLabor')}
              </div>
            </div>

            <div
              data-anime="true"
              className={StyleTemplate.solutionsTemplatePoint}
            >
              <div
                className={`${StyleTemplate.solutionsTemplatePointIcon} gl-scale`}
              />
              <div className={StyleTemplate.solutionsTemplatePointTitle}>
                {t('utilities.smartMeter')}
              </div>
              <div className={StyleTemplate.solutionsTemplatePointText}>
                {t('utilities.optimizeEnergy')}
              </div>
            </div>

            <div
              data-anime="true"
              className={StyleTemplate.solutionsTemplatePoint}
            >
              <div
                className={`${StyleTemplate.solutionsTemplatePointIcon} gl-leaf`}
              />
              <div className={StyleTemplate.solutionsTemplatePointTitle}>
                {t('utilities.reduced')}
              </div>
              <div className={StyleTemplate.solutionsTemplatePointText}>
                {t('utilities.allowUsers')}
              </div>
            </div>
          </div>

          <div className={StyleTemplate.solutionTemplateUseCaseContainer}>
            <div
              data-anime="true"
              className={StyleTemplate.solutionTemplateUseCaseContent}
            >
              <div
                className={StyleTemplate.solutionTemplateUseCaseContentTitle}
              >
                {t('utilities.case')}
              </div>

              <div className={StyleTemplate.solutionTemplateUseCaseContentText}>
                {t('utilities.withGLUtilities')}
                <br />
                <br />
                {t('utilities.usersCanSend')}
              </div>
            </div>
            <img
              data-anime="true"
              className={StyleTemplate.solutionTemplateUseCaseImage}
              src={Usecase}
            />
          </div>

          <div className={StyleTemplate.solutionsTemplateDevicePackContainer}>
            <div
              data-anime="true"
              className={
                StyleTemplate.solutionsTemplateDevicePackTitleContainer
              }
            >
              <div className={StyleTemplate.solutionsTemplateDevicePackTitle}>
                {t('utilities.devicePack')}
              </div>
              <div
                className={StyleTemplate.solutionsTemplateDevicePackDescription}
              >
                {t('utilities.basicSet')}
              </div>
            </div>
            <div className={StyleTemplate.solutionsTemplateDevicePackContent}>
              <div
                className={`${StyleTemplate.solutionsTemplateDevicePackItem} ${StyleTemplate.solutionsTemplateDevicePackContent3}`}
              >
                <img
                  data-anime="true"
                  className={StyleTemplate.solutionsTemplateDevicePackImage}
                  src={SmartUtilitiesUtilities}
                />
                <div className={StyleTemplate.solutionsTemplateDevicePackName2}>
                  <span>GL</span> Utilities
                </div>
              </div>
              <div
                className={`${StyleTemplate.solutionsTemplateDevicePackItem} ${StyleTemplate.solutionsTemplateDevicePackContent2}`}
              >
                <img
                  data-anime="true"
                  className={StyleTemplate.solutionsTemplateDevicePackImage}
                  src={SmartRetailPlug}
                />
                <div className={StyleTemplate.solutionsTemplateDevicePackName}>
                  <span>GL</span> Signal
                </div>
              </div>
              <div
                className={`${StyleTemplate.solutionsTemplateDevicePackItem} ${StyleTemplate.solutionsTemplateDevicePackContent7}`}
              >
                <img
                  data-anime="true"
                  className={StyleTemplate.solutionsTemplateDevicePackImage}
                  src={SmartUtilitiesCard}
                />
                <div className={StyleTemplate.solutionsTemplateDevicePackName2}>
                  <span>GL</span> Reader
                </div>
              </div>
            </div>
          </div>
          <Share />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Utilities;
