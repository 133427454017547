import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import topbarFontColor from "../func/topbarFontColor";
import Logo from "../Img/Default/logo-selo-main.svg";

import Styles from "../css/css-components/topbar-component.module.css";
import LanguageSwitcher from "./LanguageSwitcher";
import bootstrap, { Collapse } from "bootstrap";
import { useMedia } from "../hooks/useMedia";
import ButtonPrimaryOutline from "./ButtonPrimaryOutline";

function Topbar({ color, backgroundColor, accentColor, barsColor, num }) {
  const { t } = useTranslation()
  const { mediaIsActive: isOnMobile } = useMedia(1399)

  const [menuMobileIsShowing, setMenuMobileIsShowing] = useState(false);
  const [backgroundColorTopbar, setBackgroundColorTopbar] = useState();
  const [noInIndex, setNotInIndex] = useState(false);
  const [inTechnology, setInTechnology] = useState(false);
  const [small, setSmall] = useState(false);

  const styleVerification = noInIndex
    ? Styles.topbarCollapseTechnologyMenuItem
    : Styles.topbarCollapseMenuItem;

  const titleMobileVerification = noInIndex
    ? Styles.topbarCollapseMobileSecondaryColorButton
    : Styles.topbarCollapseMobileButtonBottom;

  const hiddenSessionOptionVerification = noInIndex
    ? Styles.topbarCollapseMobileHiddenSessionOptionSecondary
    : Styles.topbarCollapseMobileHiddenSessionOption;

  let realBackgroundColor = backgroundColor;

  switch (backgroundColor) {
    case "white":
      realBackgroundColor = "white";
      break;
    case "navy":
      realBackgroundColor = "var(--base-navy)";

      break;
    default:
      realBackgroundColor = "white";
      break;
  }

  useEffect(() => {
    let collapse = document.querySelector("#topbarCollapseSolutions");

    let topbar = document.querySelector("#topbar1");

    const benefits = document.querySelector("#benefits");
    let title = document.querySelector(".welcomeText");
    const manuals = document.querySelector("#manuals");

    if (!title) {
      setNotInIndex(true);
    }

    if (manuals) {
      setNotInIndex(false);
    }

    if (benefits) {
      setInTechnology(true);
    }
    if (topbar.classList.toggle(Styles.topbarActive, num > 50)) {
      let activeClass = document.querySelector(`.${Styles.topbarActive}`);
      setSmall(true);
    } else if (![...collapse.classList].includes("show") && num < 50) {
      let activeClass = document.querySelector(`.${Styles.topbar}`);
      activeClass.style.background = "transparent";
      setSmall(false);
      setBackgroundColorTopbar("transparent");
    }
  }, [noInIndex, inTechnology, num]);

  useEffect(() => {

    const observer = new IntersectionObserver(([entries]) => {
      const target = entries.target;

      if (!target.classList.contains("collapse")) {
        setMenuMobileIsShowing(true);
      } else {
        setMenuMobileIsShowing(false);
      }
    });

    observer.observe(document.querySelector("#topbarCollapseMobile"));

  }, [])

  useEffect(() => {
    const main = document.querySelector(".main");
    const solutionsCollapse = document.getElementById("topbarCollapseSolutions")
    const industriesCollapse = document.getElementById("topbarCollapseIndustries")
    const aboutCollapse = document.getElementById("topbarCollapseAbout")

    const solutionsButton = document.querySelector("[data-target='#topbarCollapseSolutions']")
    const industriesButton = document.querySelector("[data-target='#topbarCollapseIndustries']")
    const aboutButton = document.querySelector("[data-target='#topbarCollapseAbout']")

    const bsCollapseSolutions = new Collapse(solutionsCollapse, {
      toggle: false,
    });

    const bsCollapseIndustries = new Collapse(industriesCollapse, {
      toggle: false,
    });

    const bsCollapseAbout = new Collapse(aboutCollapse, {
      toggle: false,
    });

    const handleOutsideClick = (event) => {
      const submenus = [
        solutionsCollapse,
        industriesCollapse,
        aboutCollapse
      ];

      for (const submenu of submenus) {
        if (submenu && !submenu.contains(event.target)) {
          bsCollapseSolutions.hide();
          bsCollapseIndustries.hide();
          bsCollapseAbout.hide();
        }
      }
    };

    const handleScroll = (event) => {
      if (main.scrollTop > 50) {
        bsCollapseSolutions.hide();
        bsCollapseIndustries.hide();
        bsCollapseAbout.hide();

        solutionsButton.blur()
        industriesButton.blur()
        aboutButton.blur()
      }
    };

    document.addEventListener("click", handleOutsideClick);
    main.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      main.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={
        `${noInIndex ? Styles.topBarTechnology : Styles.topbarContainer}
        ${menuMobileIsShowing ? Styles.topbarContainerMobileMenuIsShowing : ''}
        `
      }
      id="topbarCollapseParent"
    >
      <div
        className={noInIndex ? Styles.topBarTechnology : Styles.topBarContainer}
      >
        <div className={Styles.topbar} id="topbar1">
          <Link to="/">
            <div className={Styles.topbarLogoContainer}>
              <img id="logo-image" className={Styles.topbarLogo} src={Logo} alt="Grouplink Logo" />
              <div
                className={`${Styles.topbarBrandText} ${topbarFontColor(
                  color
                )} ${Styles.topbarExpandedAlignment}`}
                id="transcend-iot"
              >
                Transcend IoT
              </div>
            </div>
          </Link>

          {isOnMobile && (
            <LanguageSwitcher isOnMobile={isOnMobile} color={color} />
          )}

          <label className={`${Styles.mobileButton} ${noInIndex ? Styles.mobileTechnologyButton : ''}`}
            htmlFor="burger"
            data-toggle="collapse"
            data-target="#topbarCollapseMobile"
            aria-expanded="false"
            aria-controls="topbarCollapseMobile"
          >
            <input type="checkbox" id="burger" />
            <span></span>
            <span></span>
            {/* <span></span> */}
          </label>

          <div
            className={`${Styles.topbarButtonsContainer} ${Styles.topbarButtonExpandedAlignment}`}
          >
            <div className={`${Styles.topbarButton}`}>
              <button
                className={`topbarNavigationStyle ${inTechnology
                  ? Styles.topBarTechnologyNavigationStyle
                  : Styles.topbarNavigationStyle | small
                    ? Styles.small
                    : Styles.topbarNavigationStyle
                  } ${topbarFontColor(color)}`}
              >
                <Link to="/Technology">
                  {t('topbar.technology')}
                </Link>
              </button>
            </div>

            <div id="solutionsButton" className={`${Styles.topbarButton}`}>
              <button
                className={`topbarNavigationStyle ${small ? Styles.small : Styles.topbarNavigationStyle
                  } ${topbarFontColor(color)}`}
                type="button"
                data-toggle="collapse"
                data-target="#topbarCollapseSolutions"
                aria-expanded="false"
                aria-controls="topbarCollapseSolutions"
              >
                <a>
                  {t('topbar.solutions')}
                </a>
              </button>
            </div>

            <div className={`${Styles.topbarButton}`}>
              <button
                className={`topbarNavigationStyle ${small ? Styles.small : Styles.topbarNavigationStyle
                  } ${topbarFontColor(color)}`}
                type="button"
                data-toggle="collapse"
                data-target="#topbarCollapseIndustries"
                aria-expanded="false"
                aria-controls="topbarCollapseDevices"
              >
                <a>
                  {t('topbar.industries')}
                </a>
              </button>
            </div>

            <div className={`${Styles.topbarButton}`}>
              <button
                className={`topbarNavigationStyle ${small ? Styles.small : Styles.topbarNavigationStyle
                  } ${topbarFontColor(color)}`}
                type="button"
                data-toggle="collapse"
                data-target="#topbarCollapseAbout"
                aria-expanded="false"
                aria-controls="topbarCollapseAbout"
              >
                <a>
                  {t('topbar.about')}
                </a>
              </button>
            </div>

            <LanguageSwitcher color={color} />

            <div className={`${Styles.topbarButton}`}>
              <div className={Styles.topbarSpecialButton}>
                <Link to="/Contact">
                  <ButtonPrimaryOutline title={t('topbar.talkSales')} />
                  {/* <button
                    className={
                      noInIndex
                        ? Styles.talkSalesButtonSecondary
                        : Styles.talkSalesButton
                    }
                  >
                    {t('topbar.talkSales')}
                  </button> */}
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Collapses */}
        <div
          className={`${Styles.topbarCollapseContainer} collapse`}
          id="topbarCollapseSolutions"
          data-parent="#topbarCollapseParent"
        >
          <div className={Styles.topbarCollapse}>
            <div className={Styles.topbarCollapseMenuContainer}>

              <div className={Styles.topbarCollapseMenuContent}>
                <div
                  className={`
                  ${Styles.topbarCollapseMenuItems}
                  ${Styles.linksContainer}
                `}
                >
                  <div>
                    <Link to="/SmartPlaces">
                      <div className={styleVerification}>
                        GL Smart Places
                      </div>
                    </Link>
                    <Link to="/SmartRetail">
                      <div className={styleVerification}>
                        GL Smart Retail
                      </div>
                    </Link>
                    {/* <Link to="/Telemetry">
                      <div className={styleVerification}>
                        GL Telemetry
                      </div>
                    </Link> */}
                  </div>
                  <div>
                    <Link to="/Tracker">
                      <div className={styleVerification}>
                        GL Tracker
                      </div>
                    </Link>

                    <Link to="/Utilities">
                      <div className={styleVerification}>
                        GL Utilities
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div
          className={`${Styles.topbarCollapseContainer} collapse`}
          id="topbarCollapseIndustries"
          data-parent="#topbarCollapseParent"
        >
          <div className={Styles.topbarCollapse}>
            <div className={Styles.linksContainer}>
              <div>
                <Link to="/AgricultureLivestock">
                  <div className={styleVerification}>
                    {t('topbar.agriculture')}
                  </div>
                </Link>
                <Link to="/BankingFinancial">
                  <div className={styleVerification}>
                    {t('topbar.banking')}
                  </div>
                </Link>
                <Link to="/EnergyUtilities">
                  <div className={styleVerification}>
                    {t('topbar.energy')}
                  </div>
                </Link>
                <Link to="/Environment">
                  <div className={styleVerification}>
                    {t('topbar.environment')}
                  </div>
                </Link>
                <Link to="/Healthcare">
                  <div className={styleVerification}>
                    {t('topbar.healthcare')}
                  </div>
                </Link>
              </div>
              <div>
                <Link to="/Manufacturing">
                  <div className={styleVerification}>
                    {t('topbar.industry')}
                  </div>
                </Link>
                <Link to="/RetailConsumer">
                  <div className={styleVerification}>
                    {t('topbar.retail')}
                  </div>
                </Link>

                <Link to="/TravelTransportation">
                  <div className={styleVerification}>
                    {t('topbar.travel')}
                  </div>
                </Link>
                <Link to="/Workspaces">
                  <div className={styleVerification}>
                    {t('topbar.workspaces')}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${Styles.topbarCollapseContainer} collapse`}
          id="topbarCollapseAbout"
          data-parent="#topbarCollapseParent"
        >
          <div className={Styles.topbarCollapse}>
            <div className={Styles.topbarCollapseMenuContainer}>
              <div className={Styles.topbarCollapseMenuContent}>
                <div className={Styles.topbarCollapseMenuItemTitle}></div>
                <div className={Styles.topbarCollapseMenuItems}>
                  <div>
                    <Link to="/Contact">
                      <div className={styleVerification}>
                        {t('topbar.getInTouch')}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${Styles.topbarCollapseMobileContainer} collapse`}
          id="topbarCollapseMobile"
        >
          <div className={Styles.topbarCollapseMobileContent}>
            <div className={Styles.topbarCollapseMobileLoginItem}>
              <Link to="/Contact">
                <button className={Styles.topbarCollapseMobileButton}>
                  {t('topbar.getInTouch')}
                </button>
              </Link>
            </div>

            <div className={Styles.topbarCollapseMobileDivider} />

            <div className={Styles.topbarCollapseMobileButtonsContainer}>
              <div className={Styles.topbarCollapseMobileLoginItem}>
                <Link to="/Technology">
                  <button className={titleMobileVerification}>
                    <div className={Styles.topbarCollapseMobileButtonName}>
                      {t('topbar.technology')}
                    </div>
                  </button>
                </Link>
              </div>

              <div className={Styles.topbarCollapseMobileLoginItem}>
                <button
                  className={titleMobileVerification}
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseSolutionsMobile"
                  aria-expanded="false"
                  aria-controls="collapseSolutionsMobile"
                >
                  <div className={Styles.topbarCollapseMobileButtonName}>
                    {t('topbar.solutions')}
                  </div>
                  <div
                    className={`${Styles.topbarCollapseMobileButtonIcon} gl-chevron-down`}
                  />
                </button>
              </div>

              <div
                className={`${Styles.topbarCollapseMobileHiddenContainer} collapse`}
                id="collapseSolutionsMobile"
                data-parent="#topbarCollapseParent"
              >
                <div
                  className={
                    noInIndex
                      ? Styles.topbarCollapseMobileHiddenContainerSecondary
                      : Styles.topbarCollapseMobileHiddenContent
                  }
                >
                  <div className={Styles.topbarCollapseMobileHiddenSessionDual}>
                    <div className={Styles.topbarCollapseMobileHiddenSession}>
                      <div
                        className={
                          Styles.topbarCollapseMobileHiddenSessionTitle
                        }
                      >
                        {t('topbar.solutions')}
                      </div>
                      <div
                        className={
                          Styles.topbarCollapseMobileHiddenSessionOptions
                        }
                      >
                        <div
                          className={Styles.topbarCollapseMobileHiddenSession}
                        >
                          <Link to="/SmartRetail">
                            <div className={hiddenSessionOptionVerification}>
                              <div
                                className={Styles.topbarCollapseMenuItemMobile}
                              >
                                <span>GL</span>SmartRetail
                              </div>
                            </div>
                          </Link>
                          <Link to="/SmartPlaces">
                            <div className={hiddenSessionOptionVerification}>
                              <div
                                className={Styles.topbarCollapseMenuItemMobile}
                              >
                                <span>GL</span>SmartPlaces
                              </div>
                            </div>
                          </Link>
                          <Link to="/Tracker">
                            <div className={hiddenSessionOptionVerification}>
                              <div
                                className={Styles.topbarCollapseMenuItemMobile}
                              >
                                <span>GL</span>Tracker
                              </div>
                            </div>
                          </Link>
                          <Link to="/Utilities">
                            <div className={hiddenSessionOptionVerification}>
                              <div
                                className={Styles.topbarCollapseMenuItemMobile}
                              >
                                <span>GL</span>Utilities
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={Styles.topbarCollapseMobileLoginItem}>
                <button
                  className={titleMobileVerification}
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseIndustriesMobile"
                  aria-expanded="false"
                  aria-controls="collapseIndustriesMobile"
                >
                  <div className={Styles.topbarCollapseMobileButtonName}>
                    {t('topbar.industries')}
                  </div>
                  <div
                    className={`${Styles.topbarCollapseMobileButtonIcon} gl-chevron-down`}
                  />
                </button>
              </div>

              <div
                className={`${Styles.topbarCollapseMobileHiddenContainer} collapse`}
                id="collapseIndustriesMobile"
                data-parent="#topbarCollapseParent"
              >
                <div
                  className={
                    noInIndex
                      ? Styles.topbarCollapseMobileHiddenContainerSecondary
                      : Styles.topbarCollapseMobileHiddenContent
                  }
                >
                  <div className={Styles.topbarCollapseMobileHiddenSessionDual}>
                    <div className={Styles.topbarCollapseMobileHiddenSession}>
                      <div
                        className={
                          Styles.topbarCollapseMobileHiddenSessionTitle
                        }
                      >
                        {t('topbar.industries')}
                      </div>
                      <div
                        className={
                          Styles.topbarCollapseMobileHiddenSessionOptions
                        }
                      >
                        <div
                          className={Styles.topbarCollapseMobileHiddenSession}
                        >
                          <Link to="/AgricultureLivestock">
                            <div className={hiddenSessionOptionVerification}>
                              {t('topbar.agriculture')}
                            </div>
                          </Link>
                          <Link to="/BankingFinancial">
                            <div className={hiddenSessionOptionVerification}>
                              {t('topbar.banking')}
                            </div>
                          </Link>
                          <Link to="/EnergyUtilities">
                            <div className={hiddenSessionOptionVerification}>
                              {t('topbar.energy')}
                            </div>
                          </Link>
                          <Link to="/Environment">
                            <div className={hiddenSessionOptionVerification}>
                              {t('topbar.environment')}
                            </div>
                          </Link>
                          <Link to="/Healthcare">
                            <div className={hiddenSessionOptionVerification}>
                              {t('topbar.healthcare')}
                            </div>
                          </Link>
                        </div>
                        <div
                          className={Styles.topbarCollapseMobileHiddenSession}
                        >
                          <Link to="/RetailConsumer">
                            <div className={hiddenSessionOptionVerification}>
                              {t('topbar.retail')}
                            </div>
                          </Link>
                          <Link to="/TravelTransportation">
                            <div className={hiddenSessionOptionVerification}>
                              {t('topbar.travel')}
                            </div>
                          </Link>
                          <Link to="/Workspaces">
                            <div className={hiddenSessionOptionVerification}>
                              {t('topbar.workspaces')}
                            </div>
                          </Link>
                          <Link to="/Manufacturing">
                            <div className={hiddenSessionOptionVerification}>
                              {t('topbar.industry')}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className={Styles.topbarCollapseMobileLoginItem}>
                <button
                  className={titleMobileVerification}
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseAboutMobile"
                  aria-expanded="false"
                  aria-controls="collapseAboutMobile"
                >
                  <div className={Styles.topbarCollapseMobileButtonName}>
                    {localizatedStr.about}
                  </div>
                  <div
                    className={`${Styles.topbarCollapseMobileButtonIcon} gl-chevron-down`}
                  />
                </button>
              </div> */}

              <div
                className={`${Styles.topbarCollapseMobileHiddenContainer} collapse`}
                id="collapseAboutMobile"
                data-parent="#topbarCollapseParent"
              >
                <div
                  className={
                    noInIndex
                      ? Styles.topbarCollapseMobileHiddenContainerSecondary
                      : Styles.topbarCollapseMobileHiddenContent
                  }
                >
                  <div className={Styles.topbarCollapseMobileHiddenSession}>
                    <div
                      className={Styles.topbarCollapseMobileHiddenSessionTitle}
                    ></div>
                    <div
                      className={
                        Styles.topbarCollapseMobileHiddenSessionOptions
                      }
                    >
                      <div className={Styles.topbarCollapseMobileHiddenSession}>
                        <Link to="/Contact">
                          <div className={hiddenSessionOptionVerification}>
                            {t('topbar.getInTouch')}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={Styles.topbarCollapseMobileLoginItem}>
                <button
                  className={titleMobileVerification}
                  data-toggle="collapse"
                  data-target="#collapseDevelopersMobile"
                  aria-expanded="false"
                  aria-controls="collapseDevelopersMobile"
                >
                  <div className={Styles.topbarCollapseMobileButtonName}>
                    Developers
                  </div>
                  <div
                    className={`${Styles.topbarCollapseMobileButtonIcon} gl-chevron-down`}
                  />
                </button>
              </div>

              <div
                className={`${Styles.topbarCollapseMobileHiddenContainer} collapse`}
                id="collapseDevelopersMobile"
                data-parent="#topbarCollapseParent"
              >
                <div
                  className={
                    noInIndex
                      ? Styles.topbarCollapseMobileHiddenContainerSecondary
                      : Styles.topbarCollapseMobileHiddenContent
                  }
                >
                  <div className={Styles.topbarCollapseMobileHiddenSession}>
                    <div
                      className={
                        Styles.topbarCollapseMobileHiddenSessionOptions
                      }
                    >
                      <div className={Styles.topbarCollapseMobileHiddenSession}>
                        <a
                          href="https://dashboard.grouplinknetwork.com/"
                          target="_blank" rel="noreferrer"
                        >
                          <div className={hiddenSessionOptionVerification}>
                            Dashboard
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={Styles.topbarCollapseMobileDivider} />

            <div className={Styles.topbarCollapseMobileSmallItems}>

              <div className={Styles.topbarCollapseMobileSmallItemContainer}>
                <button
                  className={Styles.topbarCollapseMobileSmallItem}
                  onClick={() =>
                  (window.location =
                    "https://dashboard.grouplinknetwork.com/needhelp")
                  }
                >
                  {t('topbar.helpCenter')}
                </button>
              </div>
              {/* <div className={Styles.topbarCollapseMobileSmallItemContainer}>
                            <button className={Styles.topbarCollapseMobileSmallItem}>
                                System Status
                            </button>
                        </div>
                        <div className={Styles.topbarCollapseMobileSmallItemContainer}>
                            <button className={Styles.topbarCollapseMobileSmallItem}>
                                Security
                            </button>
                        </div>
                        <div className={Styles.topbarCollapseMobileSmallItemContainer}>
                            <button className={Styles.topbarCollapseMobileSmallItem}>
                                Resources
                            </button>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
