import React, { useState, useEffect } from "react";
import TrackerIntroImage from "../Img/Tracker/tracker-img.png";
import TrackerLogoImage from "../Img/Tracker/tracker-logo.svg";
import TrackerUsecase from "../Img/Tracker/usecase-tracker.svg";
import StyleTemplate from "../css/css-templates/solutions-template.module.css";
import Topbar from "../Components/Topbar";
import Share from "../Components/Share";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet";
import SmartRetailTracker from "../Img/Tracker/tracker@2x.png";
import SmartRetailCard from "../Img/Tracker/card@2x.png";
import Scrollbar from "smooth-scrollbar";
import LocalizedStrings from "react-localization";
import { useTranslation } from "react-i18next";

function Tracker() {
  const { t } = useTranslation();

  const [numScroll, setNumScroll] = useState(30);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    var options = {
      damping: 0.06,
      alwaysShowTracks: true,
    };
    //    Scrollbar.init(document.querySelector(".main"),options);
    //    Scrollbar.getAll()[0].track.xAxis.element.remove()
    //    Scrollbar.getAll()[0].addListener((e)=>{
    //        if(e.offset.y > 70){
    //            setNumScroll(53)
    //        }else{
    //            setNumScroll(30)
    //        }
    //    })

    //    return ()=>{Scrollbar.destroyAll()}
  }, []);

  useEffect(() => {
    const target = document.querySelectorAll("[data-anime]");
    const animationClass = StyleTemplate.active;

    function animeScroll(e) {
      console.log(e);
      target.forEach((element) => {
        if (e > element.offsetTop - 500) {
          element.classList.add(animationClass);
        }
      });
    }

    animeScroll();

    window.addEventListener("scroll", animeScroll);

    // return () => {
    //   window.removeEventListener("scroll", animeScroll);
    // };
    // if (target.length) {
    //   Scrollbar.getAll()[0].addListener((e) => {
    //     animeScroll(e.offset.y);
    //   });
    // }
  }, []);

  return (
    <>
      <Topbar
        color={"white"}
        backgroundColor="white"
        barsColor="navy"
        num={numScroll}
      />
      <div
        className={`${StyleTemplate.solutionsTemplateContainer} ${StyleTemplate.fixed} main`}
      >
        <Helmet>
          <meta name="theme-color" content="#FFFFFE" />
          <title>{t('tracker.title')} - Group Link</title>
        </Helmet>
        <div className={StyleTemplate.solutionsTemplate}>
          <div className={StyleTemplate.solutionsTemplateTopIntroImgLeft}>
            <img
              src={TrackerIntroImage}
              className={StyleTemplate.solutionsTemplateTopIntroImage}
            />
            <div className={StyleTemplate.solutionsTemplateTopIntroContent}>
              <div>
                <img
                  src={TrackerLogoImage}
                  className={StyleTemplate.solutionsTemplateTopIntroLogoImage}
                />
                <div
                  className={
                    StyleTemplate.solutionsTemplateTopIntroSolutionDescription
                  }
                >
                  {t('tracker.introTitle')}
                </div>
              </div>
            </div>
          </div>

          <div className={StyleTemplate.solutionsTemplatePointsContainer}>
            <div
              data-anime="true"
              className={StyleTemplate.solutionsTemplatePoint}
            >
              <div
                className={`${StyleTemplate.solutionsTemplatePointIcon} gl-pin`}
              />
              <div className={StyleTemplate.solutionsTemplatePointTitle}>
                {t('tracker.LocationBased')}
              </div>
              <div className={StyleTemplate.solutionsTemplatePointText}>
                {t('tracker.youCanPut')}
              </div>
            </div>

            <div
              data-anime="true"
              className={StyleTemplate.solutionsTemplatePoint}
            >
              <div
                className={`${StyleTemplate.solutionsTemplatePointIcon} gl-internet`}
              />
              <div className={StyleTemplate.solutionsTemplatePointTitle}>
                {t('tracker.worldwideSupport')}
              </div>
              <div className={StyleTemplate.solutionsTemplatePointText}>
                {t('tracker.anyApp')}
              </div>
            </div>

            <div
              data-anime="true"
              className={StyleTemplate.solutionsTemplatePoint}
            >
              <div
                className={`${StyleTemplate.solutionsTemplatePointIcon} gl-target`}
              />
              <div className={StyleTemplate.solutionsTemplatePointTitle}>
                {t('tracker.positioning')}
              </div>
              <div className={StyleTemplate.solutionsTemplatePointText}>
                {t('tracker.youCanAlso')}
              </div>
            </div>
          </div>

          <div className={StyleTemplate.solutionTemplateUseCaseContainer}>
            <div
              data-anime="true"
              className={StyleTemplate.solutionTemplateUseCaseContent}
            >
              <div
                className={StyleTemplate.solutionTemplateUseCaseContentTitle}
              >
                {t('tracker.useCases')}
              </div>
              <div className={StyleTemplate.solutionTemplateUseCaseContentText}>
                {t('tracker.imagineYou')}
                <br />
                <br />
                {t('tracker.safelyAnd')}
              </div>
            </div>
            <img
              data-anime="true"
              className={StyleTemplate.solutionTemplateUseCaseImage}
              src={TrackerUsecase}
            />
          </div>

          <div className={StyleTemplate.solutionsTemplateDevicePackContainer}>
            <div
              data-anime="true"
              className={
                StyleTemplate.solutionsTemplateDevicePackTitleContainer
              }
            >
              <div className={StyleTemplate.solutionsTemplateDevicePackTitle}>
                {t('tracker.devicePack')}
              </div>
              <div
                className={StyleTemplate.solutionsTemplateDevicePackDescription}
              >
                {t('tracker.basicSet')}
              </div>
            </div>
            <div className={StyleTemplate.solutionsTemplateDevicePackContent}>
              <div
                className={`${StyleTemplate.solutionsTemplateDevicePackItem} ${StyleTemplate.solutionsTemplateDevicePackContent6}`}
              >
                <img
                  // data-anime="true"
                  className={StyleTemplate.solutionsTemplateDevicePackImage2}
                  src={SmartRetailTracker}
                />
                <div className={StyleTemplate.solutionsTemplateDevicePackName2}>
                  <span>GL</span>Pet
                </div>
              </div>
              <div
                className={`${StyleTemplate.solutionsTemplateDevicePackItem} ${StyleTemplate.solutionsTemplateDevicePackContent5}`}
              >
                <img
                  // data-anime="true"
                  className={StyleTemplate.solutionsTemplateDevicePackImage2}
                  src={SmartRetailCard}
                />
                <div className={StyleTemplate.solutionsTemplateDevicePackName}>
                  <span>GL</span>Station
                </div>
              </div>
            </div>
          </div>
          <Share />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Tracker;
