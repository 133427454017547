import React, { useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";

import Topbar from "../Components/Topbar";
import Footer from "../Components/Footer";
import Style from "../css/css-pages/manuals.module.css";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function SmartRetail({ onScroll }) {
  const { t } = useTranslation()

  const manuals = [
    {
      id: 1,
      title: t('manuals.devices'),
      manuals: [
        {
          id: 1,
          title: "GL Utilities",
          products: [
            {
              id: 1,
              title: "GL Utilities Water MDI",
              subtitle: "MDV208",
              src: '/images/products/gl-water.png',
              linkPTBR: "/manuals/gl-utilities-water-diehl-instalacao-pt-mdv208.pdf",
              linkEN: "/manuals/gl-utilities-water-diehl-instalacao-en-mdv208.pdf",
            },
            {
              id: 2,
              title: "GL Utilities Water & Gas",
              subtitle: "WGV102",
              src: '/images/products/gl-utilities.png',
              linkPTBR: "/manuals/gl-utilities-WGV102-pt-br.pdf",
              linkEN: "/manuals/gl-utilities-WGV102-en.pdf",
            },
            {
              id: 3,
              title: "GL Utilities Water & Gas",
              subtitle: "WGV208",
              src: '/images/products/gl-utilities.png',
              linkPTBR: "/manuals/gl-utilities-WGV208-pt-br.pdf",
              // linkEN: "/manuals/gl-utilities-WGV208-en.pdf",
            },
            {
              id: 4,
              title: "GL Utilities Water MSGA",
              subtitle: "MSV206",
              src: '/images/products/gl-MSV206.png',
              linkPTBR: "/manuals/gl-utilities-saga-pt.pdf",
              // linkEN: "/manuals/gl-utilities-energy-en.pdf"
            },
            {
              id: 5,
              title: "GL Utilities Water & Gas MITR",
              subtitle: "MIV204",
              src: '/images/products/gl-MIV204.png',
              linkPTBR: "/manuals/gl-utilities-itron-pt.pdf",
              // linkEN: "/manuals/gl-utilities-energy-L3-en.pdf"
            },
            {
              id: 6,
              title: "GL Utilities Energy",
              subtitle: "EMV203P",
              src: '/images/products/gl-EMV203P.png',
              linkPTBR: "/manuals/gl-utilities-EMV203P-instalacao-pt.pdf",
            },
            {
              id: 7,
              title: "GL Utilities Energy",
              src: '/images/products/gl-utilenergy.png',
              linkPTBR: "/manuals/gl-utilities-energy-pt-br.pdf",
              linkEN: "/manuals/gl-utilities-energy-en.pdf"
            },
            {
              id: 8,
              title: "GL Utilities Energy L3",
              src: '/images/products/gl-energy-l3.png',
              linkPTBR: "/manuals/gl-utilities-energy-L3-pt-br.pdf",
              linkEN: "/manuals/gl-utilities-energy-L3-en.pdf"
            },
          ]
        },
        {
          id: 2,
          title: "GL Smart Retail",
          products: [
            {
              id: 1,
              title: "GL Plug",
              src: '/images/products/gl-plug.png',
              linkPTBR: "/manuals/gl-plug-manual-pt.pdf",
              linkEN: "/manuals/gl-plug-manual-en.pdf"
            },
            {
              id: 2,
              title: "GL Station",
              src: '/images/products/gl-station.png',
              linkPTBR: "/manuals/gl-station-manual-usuario-pt.pdf",
              linkEN: "/manuals/gl-station-user-manual-en.pdf"
            }
          ]
        },
        {
          id: 3,
          title: "GL Tracker",
          products: [
            {
              id: 1,
              title: "GL Tag",
              subtitle: "2 baterias",
              src: '/images/products/gl-tag.png',
              linkPTBR: "/manuals/gl-tag-2-baterias-pt.pdf",
              linkEN: "/manuals/gl-tag-2-batteries-en.pdf",
            }
          ]
        },
      ]
    },
    {
      id: 2,
      title: t('manuals.mobileApps'),
      manuals: [
        {
          id: 1,
          title: "GL Activation",
          products: [
            {
              id: 1,
              src: '/images/products/gl-app.png',
              linkPTBR: "/manuals/gl-activation-pt.pdf",
            }
          ]
        },
      ]
    }
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Topbar color="black" backgroundColor="white" barsColor="navy" num={30} />
      <div
        onScroll={onScroll}
        className={`${Style.fixed} main`}
      >
        <Helmet>
          <meta name="theme-color" content="#FFFFFE" />
          <title>{t('manuals.title')} - Group Link</title>
        </Helmet>

        <main>
          <section id="manuals" className={Style.manualsContainer}>
            <h2>{t('manuals.title')}</h2>
            <p className={Style.subtitleOne}>{t('manuals.subtitleOne')}</p>

            <p
              className={Style.subtitleTwo}
              dangerouslySetInnerHTML={{
                __html: t('manuals.subtitleTwo')
              }} >
            </p>

            <div className={Style.manualsContent}>
              {manuals.map(manualType => (
                <div>
                  <h2>{manualType.title}</h2>
                  {manualType.manuals.map((manual) => (
                    <div key={manual.id} className={Style.manuals}>
                      <div className={Style.manualsHeader}>
                        <span></span>
                        <h3>{manual.title}</h3>
                      </div>

                      <div className={Style.products}>
                        {manual.products.map((product) => (
                          <div key={product.id} className={Style.product}>
                            <img src={product.src} alt={product.name} />

                            <div className={Style.productContent}>
                              {product.title && (
                                <span
                                  className={`${Style.productTitle} ${!product.subtitle ? Style.productTitleWithoutSub : ''} `}
                                >
                                  {product.title}
                                </span>
                              )}

                              {product.subtitle && (
                                <span className={Style.productSubtitle}>{product.subtitle}</span>
                              )}

                              <div>
                                <a href={product.linkPTBR} target="_blank" rel="noopener noreferrer">
                                  <MdOutlineFileDownload size={18} />
                                  {/* {t('manuals.download')} */}
                                  pt/br
                                </a>

                                {product.linkEN && (
                                  <a href={product.linkEN} target="_blank" rel="noopener noreferrer">
                                    <MdOutlineFileDownload size={18} />
                                    {/* {t('manuals.download')} */}
                                    en
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>

          </section>

          <Footer />
        </main>
      </div>
    </>
  );
}

export default SmartRetail;
