import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next';

import ptBRJson from './languages/ptBR.json';
import enUSJson from './languages/enUS.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        // defaultNS: ['translation'],
        // ns: ['translation'],
        interpolation: {
            escapeValue: false,
        },
        resources: {
            pt: ptBRJson,
            en: enUSJson
        }
    })
